import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import * as d3 from 'd3'
import './styles.scss'

export default function AreaChart(props) {
	const { height: pHeight } = props
	const [size, setSize] = useState([0, 0])
	const chart = useRef(null)
	const tooltip = useRef(null)
	const svg = useRef(null)
	const margin = { top: 20, right: 40, bottom: 40, left: 0 }

	const data = [
		{
			year: '0',
			label: 'Sep 2021',
			Partnerships: '1.43369',
			Seed: '0.00000',
			PrivateSale: '0.00000',
			Founders: '0.000',
			Labs: '0.32258',
			Advisors: '0.000',
			Week: '1',
		},
		{
			year: '1',
			label: 'Oct 2021',
			Partnerships: '2.867',
			Seed: '0.00000',
			PrivateSale: '0.000',
			Founders: '0.000',
			Labs: '0.645',
			Advisors: '0.000',
			Week: '2',
		},
		{
			year: '2',
			label: 'Nov 2021',
			Partnerships: '4.301',
			Seed: '0.00000',
			PrivateSale: '0.000',
			Founders: '0.000',
			Labs: '0.968',
			Advisors: '0.000',
			Week: '3',
		},
		{
			year: '3',
			label: 'Dec 2021',
			Partnerships: '5.735',
			Seed: '0.00000',
			PrivateSale: '0.000',
			Founders: '0.000',
			Labs: '1.290',
			Advisors: '0.000',
			Week: '4',
		},
		{
			year: '4',
			label: 'Jan 2022',
			Partnerships: '7.168',
			Seed: '0.00000',
			PrivateSale: '0.000',
			Founders: '0.000',
			Labs: '1.613',
			Advisors: '0.000',
			Week: '5',
		},
		{
			year: '5',
			label: 'Feb 2022',
			Partnerships: '8.602',
			Seed: '1.12366',
			PrivateSale: '5.00538',
			Founders: '0.000',
			Labs: '1.935',
			Advisors: '0.000',
			Week: '6',
		},
		{
			year: '6',
			label: 'Mar 2022',
			Partnerships: '10.036',
			Seed: '2.247',
			PrivateSale: '10.011',
			Founders: '0.000',
			Labs: '2.258',
			Advisors: '0.000',
			Week: '7',
		},
		{
			year: '7',
			label: 'Apr 2022',
			Partnerships: '11.470',
			Seed: '3.371',
			PrivateSale: '15.016',
			Founders: '0.000',
			Labs: '2.581',
			Advisors: '0.000',
			Week: '8',
		},
		{
			year: '8',
			label: 'May 2022',
			Partnerships: '12.903',
			Seed: '4.495',
			PrivateSale: '20.022',
			Founders: '0.000',
			Labs: '2.903',
			Advisors: '0.000',
			Week: '9',
		},
		{
			year: '9',
			label: 'Jun 2022',
			Partnerships: '14.337',
			Seed: '5.618',
			PrivateSale: '25.027',
			Founders: '0.000',
			Labs: '3.226',
			Advisors: '0.000',
			Week: '10',
		},
		{
			year: '10',
			label: 'Jul 2022',
			Partnerships: '15.771',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '0.000',
			Labs: '3.548',
			Advisors: '0.000',
			Week: '11',
		},
		{
			year: '11',
			label: 'Aug 2022',
			Partnerships: '17.204',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '0.000',
			Labs: '3.871',
			Advisors: '0.000',
			Week: '12',
		},
		{
			year: '12',
			label: 'Sep 2022',
			Partnerships: '18.638',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '0.000',
			Labs: '4.194',
			Advisors: '0.269',
			Week: '13',
		},
		{
			year: '13',
			label: 'Oct 2022',
			Partnerships: '20.072',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '0.000',
			Labs: '4.516',
			Advisors: '0.538',
			Week: '14',
		},
		{
			year: '14',
			label: 'Nov 2022',
			Partnerships: '21.505',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '0.000',
			Labs: '4.839',
			Advisors: '0.806',
			Week: '15',
		},
		{
			year: '15',
			label: 'Dec 2022',
			Partnerships: '22.939',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '0.000',
			Labs: '5.161',
			Advisors: '1.075',
			Week: '16',
		},
		{
			year: '16',
			label: 'Jan 2023',
			Partnerships: '24.373',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '0.000',
			Labs: '5.484',
			Advisors: '1.344',
			Week: '17',
		},
		{
			year: '17',
			label: 'Feb 2023',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '0.000',
			Labs: '5.806',
			Advisors: '1.613',
			Week: '18',
		},
		{
			year: '18',
			label: 'Mar 2023',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '0.276',
			Labs: '6.129',
			Advisors: '1.882',
			Week: '19',
		},
		{
			year: '19',
			label: 'Apr 2023',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '0.553',
			Labs: '6.452',
			Advisors: '2.151',
			Week: '20',
		},
		{
			year: '20',
			label: 'May 2023',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '0.829',
			Labs: '6.774',
			Advisors: '2.419',
			Week: '21',
		},
		{
			year: '21',
			label: 'Jun 2023',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '1.106',
			Labs: '7.097',
			Advisors: '2.688',
			Week: '22',
		},
		{
			year: '22',
			label: 'Jul 2023',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '1.382',
			Labs: '7.419',
			Advisors: '2.957',
			Week: '23',
		},
		{
			year: '23',
			label: 'Aug 2023',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '1.659',
			Labs: '7.742',
			Advisors: '3.226',
			Week: '24',
		},
		{
			year: '24',
			label: 'Sep 2023',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '1.935',
			Labs: '8.065',
			Advisors: '3.495',
			Week: '25',
		},
		{
			year: '25',
			label: 'Oct 2023',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '2.212',
			Labs: '8.387',
			Advisors: '3.763',
			Week: '26',
		},
		{
			year: '26',
			label: 'Nov 2023',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '2.488',
			Labs: '8.710',
			Advisors: '4.032',
			Week: '27',
		},
		{
			year: '27',
			label: 'Dec 2023',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '2.765',
			Labs: '9.032',
			Advisors: '4.301',
			Week: '28',
		},
		{
			year: '28',
			label: 'Jan 2024',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '3.041',
			Labs: '9.355',
			Advisors: '4.570',
			Week: '29',
		},
		{
			year: '29',
			label: 'Feb 2024',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '3.318',
			Labs: '9.677',
			Advisors: '4.839',
			Week: '30',
		},
		{
			year: '30',
			label: 'Mar 2024',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '3.594',
			Labs: '10.000',
			Advisors: '5.108',
			Week: '31',
		},
		{
			year: '31',
			label: 'Apr 2024',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '3.871',
			Labs: '10.323',
			Advisors: '5.376',
			Week: '32',
		},
		{
			year: '32',
			label: 'May 2024',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '4.147',
			Labs: '10.645',
			Advisors: '5.645',
			Week: '33',
		},
		{
			year: '33',
			label: 'Jun 2024',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '4.424',
			Labs: '10.968',
			Advisors: '5.914',
			Week: '34',
		},
		{
			year: '34',
			label: 'Jul 2024',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '4.700',
			Labs: '11.290',
			Advisors: '6.183',
			Week: '35',
		},
		{
			year: '35',
			label: 'Aug 2024',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '4.977',
			Labs: '11.613',
			Advisors: '6.452',
			Week: '36',
		},
		{
			year: '36',
			label: 'Sep 2024',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '5.253',
			Labs: '11.935',
			Advisors: '6.452',
			Week: '37',
		},
		{
			year: '37',
			label: 'Oct 2024',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '5.530',
			Labs: '12.258',
			Advisors: '6.452',
			Week: '38',
		},
		{
			year: '38',
			label: 'Nov 2024',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '5.806',
			Labs: '12.581',
			Advisors: '6.452',
			Week: '39',
		},
		{
			year: '39',
			label: 'Dec 2024',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '6.083',
			Labs: '12.903',
			Advisors: '6.452',
			Week: '40',
		},
		{
			year: '40',
			label: 'Jan 2025',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '6.359',
			Labs: '13.226',
			Advisors: '6.452',
			Week: '41',
		},
		{
			year: '41',
			label: 'Feb 2025',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '6.636',
			Labs: '13.548',
			Advisors: '6.452',
			Week: '42',
		},
		{
			year: '42',
			label: 'Mar 2025',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '6.912',
			Labs: '13.871',
			Advisors: '6.452',
			Week: '43',
		},
		{
			year: '43',
			label: 'Apr 2025',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '7.189',
			Labs: '14.194',
			Advisors: '6.452',
			Week: '44',
		},
		{
			year: '44',
			label: 'May 2025',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '7.465',
			Labs: '14.516',
			Advisors: '6.452',
			Week: '45',
		},
		{
			year: '45',
			label: 'Jun 2025',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '7.742',
			Labs: '14.839',
			Advisors: '6.452',
			Week: '46',
		},
		{
			year: '46',
			label: 'Jul 2025',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '8.018',
			Labs: '15.161',
			Advisors: '6.452',
			Week: '47',
		},
		{
			year: '47',
			label: 'Aug 2025',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '8.295',
			Labs: '15.484',
			Advisors: '6.452',
			Week: '48',
		},
		{
			year: '48',
			label: 'Sep 2025',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '8.571',
			Labs: '15.806',
			Advisors: '6.452',
			Week: '49',
		},
		{
			year: '49',
			label: 'Oct 2025',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '8.848',
			Labs: '16.129',
			Advisors: '6.452',
			Week: '50',
		},
		{
			year: '50',
			label: 'Nov 2025',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '9.124',
			Labs: '16.452',
			Advisors: '6.452',
			Week: '51',
		},
		{
			year: '51',
			label: 'Dec 2025',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '9.401',
			Labs: '16.774',
			Advisors: '6.452',
			Week: '52',
		},
		{
			year: '52',
			label: 'Jan 2026',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '9.677',
			Labs: '17.097',
			Advisors: '6.452',
			Week: '53',
		},
		{
			year: '53',
			label: 'Feb 2026',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '9.954',
			Labs: '17.419',
			Advisors: '6.452',
			Week: '54',
		},
		{
			year: '54',
			label: 'Mar 2026',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '10.230',
			Labs: '17.742',
			Advisors: '6.452',
			Week: '55',
		},
		{
			year: '55',
			label: 'Apr 2026',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '10.507',
			Labs: '18.065',
			Advisors: '6.452',
			Week: '56',
		},
		{
			year: '56',
			label: 'May 2026',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '10.783',
			Labs: '18.387',
			Advisors: '6.452',
			Week: '57',
		},
		{
			year: '57',
			label: 'Jun 2026',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '11.060',
			Labs: '18.710',
			Advisors: '6.452',
			Week: '58',
		},
		{
			year: '58',
			label: 'Jul 2026',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '11.336',
			Labs: '19.032',
			Advisors: '6.452',
			Week: '59',
		},
		{
			year: '59',
			label: 'Aug 2026',
			Partnerships: '25.806',
			Seed: '6.742',
			PrivateSale: '30.032',
			Founders: '11.613',
			Labs: '19.355',
			Advisors: '6.452',
			Week: '60',
		},
	]

	useEffect(() => {
		const width = size[0] - margin.left - margin.right,
			height = pHeight - margin.top - margin.bottom

		const svg = d3
			.select(chart.current)
			.select('svg')
			.html(null)
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom)
			.attr(
				'viewBox',
				`0 0 ${width + margin.left + margin.right} ${
					height + margin.top + margin.bottom
				}`
			)
			.append('g')
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

		d3.timeFormat('Y')

		const keys = [
			'Partnerships',
			'Seed',
			'PrivateSale',
			'Founders',
			'Labs',
			'Advisors',
		]
		const keyVals = {
			Partnerships: 'Partnerships',
			Seed: 'Seed Investors',
			PrivateSale: 'Private Sale',
			Founders: 'Founders',
			Labs: 'Cherry Labs',
			Advisors: 'Advisors',
		}

		const x = d3
			.scaleLinear()
			.domain(
				d3.extent(data, function (d) {
					return +d.year
				})
			)
			.range([0, width])

		svg
			.append('g')
			.attr('transform', 'translate(0,' + height + ')')
			.attr('class', 'd3-x-axis')
			.call(
				d3
					.axisBottom(x)
					.tickValues([0, 30, 59])
					.tickFormat((d) => {
						if (d === 0) {
							return 'September 2021'
						}
						if (d === 30) {
							return 'February 2024'
						}
						if (d === 59) {
							return 'August 2026'
						}
					})
			)

		const y = d3.scaleLinear().domain([0, 100]).range([height, 0])

		svg
			.append('g')
			.attr('transform', `translate(${width},0)`)
			.attr('class', 'd3-y-axis')
			.call(d3.axisRight(y).tickValues([1, 50, 100]))

		const color = d3
			.scaleOrdinal()
			.domain(keys)
			.range(['#C22242', '#E33B5C', '#EC4F6E', '#F85D7B', '#FE708C', '#FF879E'])

		const stackedData = d3.stack().keys(keys)(data)

		svg
			.selectAll('.stack')
			.data(stackedData)
			.enter()
			.append('path')
			.style('fill', function (d) {
				return color(d.key)
			})
			.attr(
				'd',
				d3
					.area()
					.x(function (d, i) {
						return x(d.data.year)
					})
					.y0(function (d) {
						return y(d[0])
					})
					.y1(function (d) {
						return y(d[1])
					})
			)
			.classed('stack', true)

		const xRect = d3
			.scaleBand()
			.domain(
				data.map(function (d) {
					return +d.year
				})
			)
			.range([0, width])
		const groups = svg
			.selectAll('g.cost')
			.data(stackedData)
			.enter()
			.append('g')
			.attr('class', 'cost')
			.style('fill', function (d, i) {
				return 'transparent'
			})

		const tooltipDiv = d3.select(tooltip.current)
		const container = d3.select(chart.current)
		groups
			.selectAll('.tooltipRect')
			.data(function (d) {
				d.forEach((e) => {
					e.key = d.key
				})
				return d
			})
			.enter()
			.append('rect')
			.attr('x', function (d) {
				return x(d.data.year)
			})
			.attr('y', function (d) {
				return y(d[1])
			})
			.attr('height', function (d, i) {
				return y(d[0]) - y(d[1])
			})
			.attr('width', xRect.bandwidth())
			.on('mouseover', function () {
				tooltipDiv.style('display', 'block')
				tooltipDiv.style('opacity', 1)
			})
			.on('mouseout', function () {
				tooltipDiv.style('display', 'none')
			})
			.on('mousemove', function (event, d) {
				const bcr = container.node().getBoundingClientRect()
				const width = bcr.left
				const height = bcr.top
				let x = event.clientX - width - tooltipDiv.node().clientWidth / 2
				// if (x < 0) {
				// 	x = 0
				// } else if (x + width > window.innerWidth) {
				// 	x = window.innerWidth - width
				// }
				let y = event.clientY - height - tooltipDiv.node().clientHeight - 15
				// if (y < 0) {
				// 	y = 0
				// }
				tooltipDiv.style('transform', `translate(${x}px,${y}px)`)
				tooltipDiv.html(
					`<strong><span>${d.data.label}</span></strong><br /> ${
						keyVals[d.key]
					}: </br> <strong>${(+d.data[d.key] * 3.475).toFixed(3)}M</strong>`
				)
			})
			.classed('tooltipRect', true)
		return () => {
			// cleanup
		}
	}, [size])

	useLayoutEffect(() => {
		function updateSize() {
			setSize([chart.current.clientWidth, window.innerHeight])
		}
		window.addEventListener('resize', updateSize)
		updateSize()
		return () => window.removeEventListener('resize', updateSize)
	}, [])

	return (
		<div ref={chart} className='d3-chart'>
			<div className='tooltip' ref={tooltip}></div>
			<svg ref={svg}></svg>
		</div>
	)
}
