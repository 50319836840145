import React from 'react'
import { Row, Col, Typography } from 'antd'
import { HashLink } from 'react-router-hash-link'
import logo from '../assets/logo_svg.svg'

const { Paragraph } = Typography

function AppFooter() {
	return (
		// Styling for this footer is in _components ~ #footer
		<footer id='footer'>
			<div className='container'>
				<Row justify='space-between' gutter={[32, 24]}>
					<Col span={24} sm={24} md={24} lg={24} xl={7}>
						<img src={logo} alt='cherry logo' />
						<Paragraph>
							<span className='text-15'>Cherry</span> is a community of
							idealists and technologists organized within the{' '}
							<span className='text-15'>
								Cherry Decentralized Autonomous Organization
							</span>
							, and united with the aim to improve the ability of artists and
							creators to express their art freely.
						</Paragraph>

						<div className='footer-social'>
							<div className='social-item'>
								<a
									href='https://twitter.com/Cherry_Network'
									className='icon-wrapper'
									target='_blank'
									rel='noopener noreferrer'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='20.346'
										height='16.524'
										viewBox='0 0 20.346 16.524'
									>
										<path
											id='Icon_awesome-twitter'
											data-name='Icon awesome-twitter'
											d='M18.254,7.5c.013.181.013.361.013.542A11.783,11.783,0,0,1,6.4,19.905,11.784,11.784,0,0,1,0,18.033a8.627,8.627,0,0,0,1.007.052A8.351,8.351,0,0,0,6.184,16.3a4.177,4.177,0,0,1-3.9-2.892,5.259,5.259,0,0,0,.788.065,4.41,4.41,0,0,0,1.1-.142A4.17,4.17,0,0,1,.826,9.242V9.19a4.2,4.2,0,0,0,1.885.529A4.176,4.176,0,0,1,1.42,4.142a11.853,11.853,0,0,0,8.6,4.364,4.707,4.707,0,0,1-.1-.955A4.174,4.174,0,0,1,17.131,4.7a8.21,8.21,0,0,0,2.647-1.007,4.159,4.159,0,0,1-1.833,2.3,8.359,8.359,0,0,0,2.4-.645A8.964,8.964,0,0,1,18.254,7.5Z'
											transform='translate(0 -3.381)'
											fill='#e43c5c'
										/>
									</svg>
								</a>
							</div>
							<div className='social-item'>
								<a
									href='https://cherryproject.medium.com/'
									className='icon-wrapper'
									target='_blank'
									rel='noopener noreferrer'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='19.104'
										height='15.159'
										viewBox='0 0 19.104 15.159'
									>
										<path
											id='Icon_awesome-medium-m'
											data-name='Icon awesome-medium-m'
											d='M3.262,7.593a.746.746,0,0,0-.241-.624L1.239,4.82V4.5H6.777l4.281,9.388L14.822,4.5H20.1v.32L18.578,6.281a.445.445,0,0,0-.17.427V17.451a.445.445,0,0,0,.17.427l1.489,1.461v.32H12.575v-.32l1.544-1.5c.15-.15.15-.2.15-.427V8.734l-4.293,10.9H9.4L4.4,8.734v7.3a1,1,0,0,0,.276.837l2.006,2.433v.32H1v-.316L3,16.874a.962.962,0,0,0,.257-.837Z'
											transform='translate(-0.998 -4.5)'
											fill='#e43c5c'
										/>
									</svg>
								</a>
							</div>
							<div className='social-item'>
								<a
									href='https://www.reddit.com/r/cherrynetwork'
									className='icon-wrapper'
									target='_blank'
									rel='noopener noreferrer'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='18.69'
										height='17.652'
										viewBox='0 0 18.69 17.652'
									>
										<path
											id='Icon_awesome-reddit-alien'
											data-name='Icon awesome-reddit-alien'
											d='M17.925,9a2.1,2.1,0,0,0-1.493.626,10.124,10.124,0,0,0-5.4-1.666l1.091-4.916,3.474.78a1.547,1.547,0,0,0,1.544,1.544A1.564,1.564,0,1,0,15.75,3.11l-3.837-.851a.367.367,0,0,0-.433.28l-1.2,5.42A10.251,10.251,0,0,0,4.913,9.645a2.077,2.077,0,1,0-2.415,3.3,4.409,4.409,0,0,0-.1.973c0,3.3,3.719,5.976,8.284,5.976,4.585,0,8.3-2.675,8.3-5.976a3.758,3.758,0,0,0-.122-.989A2.074,2.074,0,0,0,17.925,9ZM5.677,13.155A1.554,1.554,0,1,1,7.241,14.7,1.558,1.558,0,0,1,5.677,13.155Zm8.442,3.683c-1.434,1.434-5.48,1.434-6.913,0a.367.367,0,0,1,0-.54.379.379,0,0,1,.52,0c1.1,1.123,4.727,1.142,5.87,0a.379.379,0,0,1,.52,0A.364.364,0,0,1,14.119,16.838ZM14.088,14.7a1.554,1.554,0,1,1,1.564-1.544A1.547,1.547,0,0,1,14.088,14.7Z'
											transform='translate(-1.319 -2.243)'
											fill='#e43c5c'
										/>
									</svg>
								</a>
							</div>
							<div className='social-item'>
								<a
									href='https://steemit.com/@cherrynetwork'
									className='icon-wrapper'
									target='_blank'
									rel='noopener noreferrer'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='17.67'
										height='17.97'
										viewBox='0 0 17.67 17.97'
									>
										<path
											id='Icon_simple-steemit'
											data-name='Icon simple-steemit'
											d='M15.91,5.471a7.246,7.246,0,0,0-2.551-1.652,4.011,4.011,0,0,1,2.458-2.17A16.229,16.229,0,0,0,6.4.252,7.607,7.607,0,0,0,2.02,2.861,6.866,6.866,0,0,0,2.763,12.6c.449.384,1.451.981,1.469,1.012a4.121,4.121,0,0,1-2.759,2.127A17.506,17.506,0,0,0,7.982,17.93a13.7,13.7,0,0,0,3.128.038,7.951,7.951,0,0,0,4.756-2.341A7.146,7.146,0,0,0,15.91,5.47Zm-1.278,9.046A6.666,6.666,0,0,1,10.777,16.3a12.2,12.2,0,0,1-2.667-.012,12.679,12.679,0,0,1-3.166-.866A4.709,4.709,0,0,0,6.07,13.816a1.239,1.239,0,0,0-.038-.981A5.248,5.248,0,0,1,7.1,6.775a5.447,5.447,0,0,1,7.622.043,5.5,5.5,0,0,1-.092,7.7Z'
											transform='translate(-0.337 -0.069)'
											fill='#e43c5c'
										/>
									</svg>
								</a>
							</div>
							<div className='social-item'>
								<a
									href='https://t.me/cherrynetwork'
									className='icon-wrapper'
									target='_blank'
									rel='noopener noreferrer'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='17.935'
										height='17.936'
										viewBox='0 0 17.935 17.936'
									>
										<path
											id='Icon_awesome-telegram'
											data-name='Icon awesome-telegram'
											d='M8.968.563A8.968,8.968,0,1,0,17.935,9.53,8.966,8.966,0,0,0,8.968.563Zm4.4,6.144L11.9,13.642c-.108.492-.4.611-.81.38L8.848,12.369,7.767,13.41a.566.566,0,0,1-.452.221l.159-2.282L11.629,7.6c.181-.159-.04-.25-.278-.09L6.216,10.738,4,10.047c-.481-.152-.492-.481.1-.712L12.75,6c.4-.145.752.1.622.705Z'
											transform='translate(0 -0.563)'
											fill='#e43c5c'
										/>
									</svg>
								</a>
							</div>
							<div className='social-item'>
								<a
									href='https:/linkedin.com/company/cherrynetwork'
									className='icon-wrapper'
									target='_blank'
									rel='noopener noreferrer'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='16.969'
										height='16.969'
										viewBox='0 0 16.969 16.969'
									>
										<path
											id='Icon_awesome-linkedin-in'
											data-name='Icon awesome-linkedin-in'
											d='M3.8,16.97H.28V5.64H3.8ZM2.037,4.095A2.047,2.047,0,1,1,4.075,2.038,2.055,2.055,0,0,1,2.037,4.095ZM16.966,16.97H13.455V11.455c0-1.314-.027-3-1.829-3-1.829,0-2.109,1.428-2.109,2.905v5.61H6V5.64H9.376V7.186h.049a3.7,3.7,0,0,1,3.329-1.83c3.561,0,4.215,2.345,4.215,5.39V16.97Z'
											transform='translate(0 0)'
											fill='#e43c5c'
										/>
									</svg>
								</a>
							</div>
							<div className='social-item'>
								<a
									href='https://www.instagram.com/cherry_network/'
									className='icon-wrapper'
									target='_blank'
									rel='noopener noreferrer'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='16.973'
										height='16.969'
										viewBox='0 0 16.973 16.969'
									>
										<path
											id='Icon_awesome-instagram'
											data-name='Icon awesome-instagram'
											d='M8.483,6.372a4.351,4.351,0,1,0,4.351,4.351A4.344,4.344,0,0,0,8.483,6.372Zm0,7.179a2.828,2.828,0,1,1,2.828-2.828A2.834,2.834,0,0,1,8.483,13.551Zm5.543-7.357a1.015,1.015,0,1,1-1.015-1.015A1.012,1.012,0,0,1,14.026,6.194Zm2.881,1.03a5.022,5.022,0,0,0-1.371-3.555A5.055,5.055,0,0,0,11.982,2.3c-1.4-.08-5.6-.08-7,0A5.048,5.048,0,0,0,1.425,3.664,5.038,5.038,0,0,0,.054,7.22c-.08,1.4-.08,5.6,0,7a5.022,5.022,0,0,0,1.371,3.555,5.061,5.061,0,0,0,3.555,1.371c1.4.08,5.6.08,7,0a5.022,5.022,0,0,0,3.555-1.371,5.055,5.055,0,0,0,1.371-3.555c.08-1.4.08-5.6,0-7Zm-1.81,8.5a2.864,2.864,0,0,1-1.613,1.613c-1.117.443-3.768.341-5,.341s-3.889.1-5-.341a2.864,2.864,0,0,1-1.613-1.613c-.443-1.117-.341-3.768-.341-5s-.1-3.889.341-5A2.864,2.864,0,0,1,3.481,4.107c1.117-.443,3.768-.341,5-.341s3.889-.1,5,.341A2.864,2.864,0,0,1,15.1,5.72c.443,1.117.341,3.768.341,5S15.541,14.611,15.1,15.724Z'
											transform='translate(0.005 -2.238)'
											fill='#e43c5c'
										/>
									</svg>
								</a>
							</div>
							<div className='social-item'>
								<a
									href='https://discord.gg/KAwC9aafWa'
									className='icon-wrapper'
									target='_blank'
									rel='noopener noreferrer'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='16.68'
										height='19.063'
										viewBox='0 0 16.68 19.063'
									>
										<path
											id='Icon_awesome-discord'
											data-name='Icon awesome-discord'
											d='M11.066,9.055A.976.976,0,1,1,10.094,8,1.015,1.015,0,0,1,11.066,9.055ZM6.615,8a1.062,1.062,0,0,0,0,2.116,1.015,1.015,0,0,0,.972-1.058A1.009,1.009,0,0,0,6.615,8ZM16.68,1.963v17.1c-2.4-2.122-1.633-1.42-4.423-4.013l.505,1.763H1.954A1.959,1.959,0,0,1,0,14.85V1.963A1.959,1.959,0,0,1,1.954,0H14.726A1.959,1.959,0,0,1,16.68,1.963ZM13.963,11a12.764,12.764,0,0,0-1.373-5.557,4.713,4.713,0,0,0-2.678-1l-.133.153A6.343,6.343,0,0,1,12.153,5.8a8.1,8.1,0,0,0-7.12-.276C4.68,5.69,4.47,5.8,4.47,5.8A6.43,6.43,0,0,1,6.977,4.556l-.1-.114a4.713,4.713,0,0,0-2.678,1A12.764,12.764,0,0,0,2.831,11a3.456,3.456,0,0,0,2.907,1.449s.353-.429.639-.791a2.964,2.964,0,0,1-1.668-1.125c.14.1.371.225.391.238a6.948,6.948,0,0,0,5.948.334,5.454,5.454,0,0,0,1.1-.562,3.008,3.008,0,0,1-1.725,1.134c.286.362.629.772.629.772A3.484,3.484,0,0,0,13.963,11Z'
											fill='#e43c5c'
										/>
									</svg>
								</a>
							</div>
							<div className='social-item'>
								<a
									href='https://forum.cherry.network/'
									className='icon-wrapper'
									target='_blank'
									rel='noopener noreferrer'
								>
									<svg
										id='chat'
										xmlns='http://www.w3.org/2000/svg'
										width='18.828'
										height='17.652'
										viewBox='0 0 18.828 17.652'
									>
										<g
											id='Group_5'
											data-name='Group 5'
											transform='translate(0 0)'
										>
											<g
												id='Group_4'
												data-name='Group 4'
												transform='translate(0 0)'
											>
												<path
													id='Path_170'
													data-name='Path 170'
													d='M13.864,15.988H1.655A1.657,1.657,0,0,0,0,17.643v8.826a1.656,1.656,0,0,0,1.655,1.655H3.31v2.758a.552.552,0,0,0,.941.39l2.986-2.986a.551.551,0,0,1,.39-.162h6.236a1.657,1.657,0,0,0,1.655-1.655V17.643A1.657,1.657,0,0,0,13.864,15.988ZM2.758,19.3h7.8a.552.552,0,0,1,0,1.1h-7.8a.552.552,0,0,1,0-1.1Zm5.59,5.516H2.758a.552.552,0,1,1,0-1.1h5.59a.552.552,0,1,1,0,1.1Zm4.413-2.206h-10a.552.552,0,1,1,0-1.1h10a.552.552,0,1,1,0,1.1Z'
													transform='translate(0 -15.988)'
													fill='#e43c5c'
												/>
											</g>
										</g>
										<g
											id='Group_7'
											data-name='Group 7'
											transform='translate(6.753 2.206)'
										>
											<g
												id='Group_6'
												data-name='Group 6'
												transform='translate(0 0)'
											>
												<path
													id='Path_171'
													data-name='Path 171'
													d='M194.051,75.989h-.544v8.274a2.768,2.768,0,0,1-2.765,2.758h-5.934l-1.177,1.1h4.676l3.148,3.155a.552.552,0,0,0,.942-.39V88.117h1.655a1.65,1.65,0,0,0,1.655-1.647V77.644A1.657,1.657,0,0,0,194.051,75.989Z'
													transform='translate(-183.631 -75.989)'
													fill='#e43c5c'
												/>
											</g>
										</g>
									</svg>
								</a>
							</div>
						</div>
					</Col>
					<Col span={24} sm={24} md={24} lg={24} xl={17}>
						<div className='column'>
							<div className='list'>
								<b
									style={{
										color: '#E33B5C',
										fontSize: '16px',
										lineHeight: '1.6rem',
									}}
								>
									Resources
								</b>
								<HashLink smooth to={'/#social-network-for-creators'}>
									<span className='text-15'>Home</span>
								</HashLink>
								<HashLink smooth to={'/#roadmap'}>
									<span className='text-15'>Roadmap</span>
								</HashLink>
								<HashLink smooth to={'/brand'}>
									<span className='text-15'>Brand</span>
								</HashLink>
								<span className='text-15'>News</span>
								<span className='text-15'>
									<a
										href='https://assets.cherry.network/deck.pdf'
										target='_blank'
										rel='noreferrer'
									>
										Pitch Deck
									</a>
								</span>
								<HashLink smooth to={'/#faq'}>
									FAQs
								</HashLink>
							</div>
							<div className='list'>
								<b
									style={{
										color: '#E33B5C',
										fontSize: '16px',
										lineHeight: '1.6rem',
									}}
								>
									Developers
								</b>
								<span className='text-15'>Technical Specifications</span>

								<span className='text-15'> Documentation</span>

								<span className='text-15'>Bug Bounty </span>
							</div>
						</div>
						<div className='column'>
							<div className='list'>
								<b
									style={{
										color: '#E33B5C',
										fontSize: '16px',
										lineHeight: '1.6rem',
									}}
								>
									Cherry Social
								</b>

								<span className='text-15'>Coming Soon </span>
							</div>
							<div className='list'>
								<b
									style={{
										color: '#E33B5C',
										fontSize: '16px',
										lineHeight: '1.6rem',
									}}
								>
									Cherry Marketplace
								</b>
								<span className='text-15'>Coming Soon </span>
							</div>
							<div className='list'>
								<b
									style={{
										color: '#E33B5C',
										fontSize: '16px',
										lineHeight: '1.6rem',
									}}
								>
									Cherry Labs
								</b>
								<span className='text-15'>Active Grants </span>
								<span className='text-15'>General Proposal </span>
							</div>
						</div>
						<div className='column'>
							<div className='list'>
								<b
									style={{
										color: '#E33B5C',
										fontSize: '16px',
										lineHeight: '1.6rem',
									}}
								>
									Cherry Finance
								</b>

								<span className='text-15'>Fund Raise </span>

								<span className='text-15'>Cherry Token </span>

								<span className='text-15'> Earn Interest</span>

								<span className='text-15'> Get Funded</span>
							</div>
							<div className='list'>
								<b
									style={{
										color: '#E33B5C',
										fontSize: '16px',
										lineHeight: '1.6rem',
									}}
								>
									Cherry Governance
								</b>

								<span className='text-15'>Forum </span>

								<span className='text-15'>Treasury </span>

								<span className='text-15'>Vote </span>
							</div>
						</div>
						<div className='column'>
							<div className='list'>
								<b
									style={{
										color: '#E33B5C',
										fontSize: '16px',
										lineHeight: '1.6rem',
									}}
								>
									Misc
								</b>
								<span className='text-15'> Terms and Conditions</span>
								<span className='text-15'>Privacy Policy</span>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			<hr />
			<div
				className='container text-center'
				style={{
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
				}}
			>
				<Paragraph
					style={{
						fontSize: '15px',
						lineHeight: '1.6rem',
					}}
				>
					Made with{' '}
					<i
						className='fas fa-heart'
						style={{ color: '#e33b5c', marginRight: '5px', marginLeft: '5px' }}
					></i>{' '}
					for Creators
				</Paragraph>
			</div>
		</footer>
	)
}

export default AppFooter
