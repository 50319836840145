import React, { useState } from 'react'
import { BlockLoading } from 'react-loadingg'
import Icon from '../../components/Icon'
import { Col, Row, Button, Typography, Layout, Input } from 'antd'
// import { saveAs } from 'file-saver'
import { useMutation, gql } from '@apollo/client'

const ADD_TO_NEWSLETTER = gql`
	mutation CreateNewsletter($email: String!) {
		createNewsLetter(data: { email: $email }) {
			email
		}
	}
`

const { REACT_APP_CDN_ENDPOINT: CDN, REACT_APP_TOKEN_SALE_DATE: TSD } =
	process.env
const { Content } = Layout
const { Title, Text, Paragraph } = Typography

export default function BrandAssets(props) {
	const [email, setEmail] = useState('')
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const [createNewsLetter, { data }] = useMutation(ADD_TO_NEWSLETTER, {
		onError: (err) => {
			console.log('eeee')
			console.log(err)
		},
	})

	function isEmail(val) {
		let regEmail =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		if (!regEmail.test(val)) {
			return false
		} else {
			return true
		}
	}

	const meetCommunityEmailCalled = async () => {
		console.log('Is Email ' + isEmail(email))

		setErrorMessage('')
		if (email === '' && email === '') {
			setErrorMessage('Please enter your email')
		} else if (!isEmail(email)) {
			setErrorMessage('Check Email Format')
		} else {
			setLoading(true)
			try {
				const response = await createNewsLetter({
					variables: {
						email: email,
					},
					errorPolicy: 'all',
				})
				// console.log(JSON.stringify(response) + ' Is the response ')
				setEmail('')
				setLoading(false)
			} catch (e) {
				// do something with the error here
				console.log('GQL ERRRROORR')
				console.log(e)
			}
		}
	}

	return (
		<div>
			<Layout className='brand-assets-layout'>
				<Content className='brand-assets-content'>
					<section className='brand-assets-hero' id='news-top-main-section'>
						<img
							src={`${CDN}/landing/token-sale/curve_line.svg`}
							alt='background'
							className='bg-image'
						/>
						<div className='container'>
							<Row justify='space-between' align='middle' gutter={[40, 50]}>
								<Col md={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
									<div className='top-left-container'>
										<Title level={1} className='newsroom-main-title'>
											Welcome to our{' '}
											<span style={{ color: '#E33B5C' }}>Press Room</span>
										</Title>
										<Row>
											<Col span={24} xs={24} md={24} sm={24}>
												<Text className='text-20'>
													<b>Promotional</b> and <b>Press packages</b> are
													important to us. We have a few templates and options
													for anyone who would like to use them.
												</Text>
											</Col>
										</Row>
									</div>
								</Col>
								<Col md={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
									<div className='image-wrap'>
										<img
											src={`${CDN}/landing/brand/press_room.svg`}
											alt='background'
											className='image'
										/>
									</div>
								</Col>
							</Row>
						</div>
					</section>

					<section className='cherry-logos'>
						<Title level={2} className='text-center'>
							<span style={{ color: '#E33B5C' }}>Cherry</span> Logos
						</Title>
						<div className='container'>
							<Row justify='space-between' align='middle' gutter={[40, 50]}>
								<Col span={24} lg={8}>
									<div className='image-wrap'>
										<img
											src={`${CDN}/landing/brand/logo_cherry.svg`}
											alt='background'
											className='bg-image'
										/>
									</div>
									<div className='download-links'>
										<Download
											fileName='cherry_text'
											fileURL={`${CDN}/landing/brand/logo_cherry.png`}
											text='Download PNG'
										/>
										<Download
											fileName='cherry_text'
											fileURL={`${CDN}/landing/brand/logo_cherry.svg`}
											text='Download SVG'
										/>
									</div>
								</Col>
								<Col span={24} lg={8}>
									<div className='image-wrap'>
										<img
											src={`${CDN}/landing/brand/logo_icon.svg`}
											alt='background'
											className='bg-image'
										/>
									</div>
									<div className='download-links'>
										<Download
											fileName='cherry_icon'
											fileURL={`${CDN}/landing/brand/logo_icon.png`}
											text='Download PNG'
										/>
										<Download
											fileName='cherry_icon'
											fileURL={`${CDN}/landing/brand/logo_icon.svg`}
											text='Download SVG'
										/>
									</div>
								</Col>
								<Col span={24} lg={8}>
									<div className='image-wrap'>
										<img
											src={`${CDN}/landing/brand/logo.svg`}
											alt='background'
											className='bg-image'
										/>
									</div>
									<div className='download-links'>
										<Download
											fileName='cherry_logo'
											fileURL={`${CDN}/landing/brand/logo.png`}
											text='Download PNG'
										/>
										<Download
											fileName='cherry_logo'
											fileURL={`${CDN}/landing/brand/logo.svg`}
											text='Download SVG'
										/>
									</div>
								</Col>
								<Col span={24} lg={12}>
									<div className='image-wrap'>
										<img
											src={`${CDN}/landing/brand/logo_black.svg`}
											alt='background'
											className='bg-image'
										/>
									</div>
									<div className='download-links'>
										<Download
											fileName='cherry_logo_black'
											fileURL={`${CDN}/landing/brand/logo_black.png`}
											text='Download PNG'
										/>
										<Download
											fileName='cherry_logo_black'
											fileURL={`${CDN}/landing/brand/logo_black.svg`}
											text='Download SVG'
										/>
									</div>
								</Col>
								<Col span={24} lg={12}>
									<div
										className='image-wrap'
										style={{ backgroundColor: '#252423' }}
									>
										<img
											src={`${CDN}/landing/brand/logo_white.svg`}
											alt='background'
											className='bg-image'
										/>
									</div>
									<div className='download-links'>
										<Download
											fileName='cherry_logo_white'
											fileURL={`${CDN}/landing/brand/logo_white.png`}
											text='Download PNG'
										/>
										<Download
											fileName='cherry_logo_white'
											fileURL={`${CDN}/landing/brand/logo_white.svg`}
											text='Download SVG'
										/>
									</div>
								</Col>
							</Row>
						</div>
					</section>

					<section className='join-the-cherry-ecosystem'>
						<div className='main-container'>
							{/* <img
								src={`${CDN}/landing/home/3_people.svg`}
								alt='Cherry Labs'
								className='image-ecosystem'
							/> */}
							<Row justify='space-between' align='middle' gutter={[40, 20]}>
								<Col span={24} sm={24} lg={13} md={24}>
									<Title level={2}>Download the Cherry Media Kit</Title>
									<div className='separator'>
										<div className='column'>
											<Paragraph className='text-white text-20'>
												Welcome to the central point for Cherry Media. Download
												the official Cherry logos, colors and branding usage
												guidelines.
											</Paragraph>
											<a
												className='ant-btn'
												href={`${CDN}/landing/brand/Cherry_Press_Kit.zip`}
											>
												<Icon name='download' />
												<b>Download ZIP</b>
											</a>
										</div>
									</div>
								</Col>
								<Col span={24} lg={10}>
									<div className='image-wrap'>
										<img
											src={`${CDN}/landing/home/3_people.svg`}
											alt='Cherry Labs'
											// className='image-ecosystem'
										/>
									</div>
								</Col>
							</Row>
						</div>
					</section>

					<section className='branding-guides'>
						<Title level={2} className='text-center'>
							Download <br /> <span style={{ color: '#E33B5C' }}>Cherry</span>{' '}
							Branding Guides
						</Title>
						<div className='container'>
							<Row justify='space-between' align='middle' gutter={[40, 50]}>
								<Col span={24} lg={12}>
									<div className='image-wrap'>
										<Icon name='pdf' />
										<Text>
											<b>Branding Guides</b>
										</Text>
									</div>
									<Download
										fileName='Branding_Guidelines'
										fileURL={`${CDN}/landing/brand/Branding_Guidelines.pdf`}
										text='Download PDF'
									/>
								</Col>
								<Col span={24} lg={12}>
									<div className='image-wrap'>
										<Icon name='pdf' />
										<Text>
											<b>Color Palette</b>
										</Text>
									</div>
									<Download
										fileName='Color_Palette'
										fileURL={`${CDN}/landing/brand/color_palette.pdf`}
										text='Download PDF'
									/>
								</Col>
							</Row>
						</div>
					</section>

					<section className='meet-the-worldwide-community' id='community'>
						<div className='container main-container'>
							<Row justify='space-around' align='middle' gutter={[40, 20]}>
								<Col span={24} lg={10}>
									<div className='social-icons'>
										<a
											href='https://twitter.com/Cherry_Network'
											className='icon-wrapper'
											target='_blank'
											rel='noopener noreferrer'
										>
											<Icon name='twitter' width='85%' height='85%' />
										</a>
										<a
											href='https://discord.gg/KAwC9aafWa'
											className='icon-wrapper'
											target='_blank'
											rel='noopener noreferrer'
										>
											<Icon name='discord' width='85%' height='85%' />
										</a>
										<a
											href='https://t.me/cherrynetwork'
											className='icon-wrapper'
											target='_blank'
											rel='noopener noreferrer'
										>
											<Icon name='telegram' width='85%' height='85%' />
										</a>
										<a
											href='https://forum.cherry.network/'
											className='icon-wrapper'
											target='_blank'
											rel='noopener noreferrer'
										>
											<Icon name='messenger' width='85%' height='85%' />
										</a>
									</div>
								</Col>
								<Col span={24} lg={14}>
									<Title level={1} style={{ color: '#E33B5C' }}>
										Need more help?
									</Title>
									<Text>
										Join a fast-growing community of developers and innovators
										connected all over the world, building the new era of the
										internet.
									</Text>
									<form className='input-email'>
										<Input
											type='email'
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											placeholder={
												errorMessage === '' ? 'Enter your email' : errorMessage
											}
										></Input>
										<Button onClick={meetCommunityEmailCalled}>
											{loading ? (
												<div className='loading'>
													<BlockLoading
														size='small'
														color='#fff'
														style={{ marginLeft: '10px' }}
													/>
												</div>
											) : (
												<Icon name='arrow-right-icon' />
											)}
										</Button>
									</form>
									<p>
										{errorMessage === 'Check Email Format' ? errorMessage : ''}
									</p>
								</Col>
							</Row>
						</div>
					</section>
				</Content>
			</Layout>
		</div>
	)
}

function Download(props) {
	const { fileURL, fileName, text } = props
	const [downloading, setDownloading] = useState(false)

	const download = (url, name) => {
		setDownloading(true)
		fetch(url)
			.then((response) => response.blob())
			.then((blob) => {
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = name
				link.click()
			})
			.then(() => {
				setDownloading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	return (
		<Button
			className='download-btn'
			onClick={() => download(fileURL, fileName)}
		>
			{downloading ? (
				<div className='loading'>
					<BlockLoading
						size='small'
						color='#E33B5C'
						style={{ marginRight: '0.75rem' }}
					/>
				</div>
			) : (
				<Icon name='download' />
			)}
			{text}
		</Button>
	)
}
