import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'

import Icon from '../../components/Icon'

import { Col, Row, Button, Typography, Layout, Input, Card, Spin } from 'antd'
import { gql, useLazyQuery } from '@apollo/client'

import referrals from '../../assets/images/referrals.svg'
import email_not_found from '../../assets/images/email_not_found.svg'

const { REACT_APP_CDN_ENDPOINT: CDN } = process.env

const { Content } = Layout
const { Text, Title } = Typography

// lolalight55@gmail.com
// rinkujatt07@gmail.com
// surajitmahanta21996@gmail.com
// piyushkumar10293847@gmail.com

const FIND_ENTRIES = gql`
	query FindEntries($email: String!) {
		findEntry(data: { email: $email }) {
			id
			address
			chain
			createdAt
			discord
			email
			instagram
			name
			reddit
			ref
			referred {
				telegram
				submittedAt
			}
			submittedAt
			telegram
			twitter
		}
	}
`
const DATE_OPTIONS = {
	weekday: 'short',
	year: 'numeric',
	month: 'short',
	day: 'numeric',
}

// findEntry(data: { email: $email }) {

export default function Referrals(props) {
	const [emailAddress, setEmailAddress] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [responseMade, setResponseMade] = useState(false)
	const [count, setCount] = useState(0)
	const [countdown, setCountdown] = useState(5)
	const statsSection = useRef(null)

	// const [findEntry, { data }] = useQuery(FINDENTRIES, {
	// 	onError: (err) => {
	// 		console.log('eeee')
	// 		console.log(err)
	// 	},
	// })

	// const { loading, error, data, refetch } = useQuery(FINDENTRIES, {
	// 	variables: { email },
	// })

	const [findEntry, { loading, data }] = useLazyQuery(FIND_ENTRIES, {
		fetchPolicy: 'no-cache',
		onCompleted: (someData) => {
			console.log('Data :::::: ' + someData)
			/* do your staff */
		},
	})

	function isEmail(val) {
		let regEmail =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		if (!regEmail.test(val)) {
			return 'Invalid Email'
		}
	}

	const delay = (ms) => new Promise((res) => setTimeout(res, ms))

	useEffect(() => {
		console.log('Data Changed - ' + count)

		// skip initial render
		return () => {
			console.log('Count - ' + count)
			setCount(count + 1)
			const redirectToRegistration = async () => {
				await delay(5000)
				// window.open('https://airdrop.cherry.network', '_blank')
			}
			// code run only on every `data` change
			if (data) {
				// only when `data` not empty - after first and futher data loading
				console.log('------- Data Not Found -------' + data)

				redirectToRegistration()
			} else {
				console.log('------- Data Found -------' + data)
				// setEmailAddress('')
			}
		}
	}, [data])

	const submitForm = async (e) => {
		setErrorMessage('')
		setResponseMade(false)
		if (isEmail(emailAddress) || emailAddress === '') {
			setErrorMessage('*Email is not correctly formatted')
			console.log('Email is not correctly formatted')
		} else {
			console.log('Finding Entries | SET Loading to true')

			try {
				console.log('got here to finding entry: ---- ' + emailAddress)
				const response = findEntry({
					variables: {
						email: emailAddress,
					},
				})

				console.log('Data ' + JSON.stringify(data))
				console.log('Loading ' + loading)
			} catch (e) {
				// do something with the error here
				setErrorMessage('Error Submitting your request. Please try again!')
				console.log('GQL ERRRROORR')
				console.log(e)
			}
		}
	}

	useEffect(() => {
		if (data?.findEntry === null) {
			countDown(5).then((res) => {
				// let airdrop = window.open('https://airdrop.cherry.network', '_blank')
				// airdrop.focus()
				window.location.href = 'https://airdrop.cherry.network'
			})
		}
	}, [data])

	useLayoutEffect(() => {
		if (data?.findEntry) {
			statsSection.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
		}
		if (data?.findEntry === null) {
			statsSection.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
		}
	}, [data])

	const countDown = (secs) => {
		let count = secs
		return new Promise((resolve, reject) => {
			let countInterval = setInterval(() => {
				if (count < 0) {
					clearInterval(countInterval)
					resolve(count)
				} else {
					setCountdown(count)
					count -= 1
				}
			}, 1000)
		})
	}

	return (
		<div>
			<Layout className='airdrop-layout'>
				<Content className='airdrop-content'>
					<section
						className='airdrop-top-main-section'
						id='airdrop-top-main-section'
					>
						<div className='container main-container'>
							<Row justify='space-between' align='middle' gutter={[40, 20]}>
								<Col
									md={{ span: 12, order: 1 }}
									xs={{ span: 24, order: 2 }}
									sm={{ span: 24, order: 2 }}
								>
									<div className='top-left-container'>
										<Title level={2} className='airdrop-main-title'>
											<span style={{ color: '#E33B5C', textAlign: 'left' }}>
												Check your referral account status
											</span>
										</Title>

										<Row>
											<Col span={24} md={20} sm={22} xs={22}>
												<form className='input-email'>
													<Input
														type='email'
														value={emailAddress}
														onChange={(e) => setEmailAddress(e.target.value)}
														placeholder={
															errorMessage === ''
																? 'Search your email'
																: errorMessage
														}
													></Input>
													<Button onClick={submitForm}>
														{loading ? (
															<Spin style={{ marginRight: '8px' }} />
														) : (
															<Icon name='arrow-right-icon' />
														)}
													</Button>
												</form>
											</Col>
											<Col
												span={24}
												xs={24}
												md={24}
												sm={24}
												style={{ marginTop: '28px' }}
											>
												<Text className='text-20'>
													Havent Submitted your referral details yet?
												</Text>
											</Col>

											<Col span={24} xs={24} md={24} sm={24}>
												<Text className='text-20'>
													<a
														href='https://airdrop.cherry.network'
														target='_blank'
														rel='noreferrer'
														className='external-link primary'
														style={{ marginRight: '8px' }}
													>
														Click here to get the airdrop.
													</a>
													It only takes 5 minutes.
												</Text>
											</Col>
										</Row>
									</div>
								</Col>
								<Col
									md={{ span: 12, order: 2 }}
									xs={{ span: 22, order: 1 }}
									sm={{ span: 22, order: 1 }}
								>
									{data?.findEntry === null ? (
										<div className='image-wrap'>
											<img
												src={email_not_found}
												alt='Social Network for Creators'
												width='100%'
											/>
										</div>
									) : (
										<div className='image-wrap'>
											<img
												src={referrals}
												alt='Social Network for Creators'
												width='100%'
											/>
										</div>
									)}
								</Col>
							</Row>
						</div>
					</section>

					{!loading && data !== undefined && data.findEntry !== null && (
						<section
							className='stats-section'
							id='stats-section'
							ref={statsSection}
						>
							<div className='container stats-container'>
								<Row justify='space-between' align='top' gutter={[40, 20]}>
									<Col span={24} sm={24} md={24}>
										<Title
											level={2}
											className='newsroom-main-title'
											style={{ textAlign: 'center' }}
										>
											<span style={{ color: '#E33B5C' }}>
												{' '}
												{data !== undefined &&
													data.findEntry?.name !== undefined &&
													data.findEntry.name}
												{' - '}
												Account Stats
											</span>{' '}
										</Title>
									</Col>

									<Col span={8} xs={24} sm={24} md={8}>
										<Card size='small' className='stat-card'>
											<Title level={5}>
												<span style={{ color: '#E33B5C', textAlign: 'left' }}>
													General Information
												</span>
											</Title>

											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='23.776'
													height='23.776'
													viewBox='0 0 23.776 23.776'
												>
													<path
														id='Icon_awesome-user-alt'
														data-name='Icon awesome-user-alt'
														d='M11.888,13.374A6.687,6.687,0,1,0,5.2,6.687,6.689,6.689,0,0,0,11.888,13.374Zm5.944,1.486H15.274a8.084,8.084,0,0,1-6.771,0H5.944A5.944,5.944,0,0,0,0,20.8v.743a2.23,2.23,0,0,0,2.229,2.229H21.547a2.23,2.23,0,0,0,2.229-2.229V20.8A5.944,5.944,0,0,0,17.832,14.86Z'
														fill='#e43c5c'
													/>
												</svg>

												<p className='text-16 ml-10'>{emailAddress}</p>
											</div>

											<Text className='text-20'>
												<p className='text-16'>
													<span
														className='primary'
														// style={{ backgroundColor: '#E43C5C' }}
													>
														[
														{data !== undefined &&
															data.findEntry.chain !== undefined &&
															data.findEntry.chain.toUpperCase()}
														]
													</span>
													<span
														className='ml-10'
														style={{ marginRight: '5px' }}
													>
														{data !== undefined &&
															data.findEntry.address !== undefined &&
															data.findEntry.address.substring(0, 20) + '...'}
													</span>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='10.726'
														height='10.726'
														viewBox='0 0 12.726 12.726'
														style={{ marginBottom: '15px', cursor: 'pointer' }}
													>
														<g
															id='Icon_feather-external-link'
															data-name='Icon feather-external-link'
															transform='translate(-3.75 -3.439)'
														>
															<path
																id='Path_15091'
																data-name='Path 15091'
																d='M13.6,13.245v3.638A1.213,1.213,0,0,1,12.383,18.1H5.713A1.213,1.213,0,0,1,4.5,16.883v-6.67A1.213,1.213,0,0,1,5.713,9H9.351'
																transform='translate(0 -2.681)'
																fill='none'
																stroke='#e43c5c'
																stroke-linecap='round'
																stroke-linejoin='round'
																stroke-width='1.5'
															/>
															<path
																id='Path_15092'
																data-name='Path 15092'
																d='M22.5,4.5h3.638V8.138'
																transform='translate(-10.723)'
																fill='none'
																stroke='#e43c5c'
																stroke-linecap='round'
																stroke-linejoin='round'
																stroke-width='1.5'
															/>
															<path
																id='Path_15093'
																data-name='Path 15093'
																d='M15,11.17,21.67,4.5'
																transform='translate(-6.255)'
																fill='none'
																stroke='#e43c5c'
																stroke-linecap='round'
																stroke-linejoin='round'
																stroke-width='1.5'
															/>
														</g>
													</svg>
												</p>
											</Text>

											<Title level={5}>
												<span style={{ color: '#E33B5C', textAlign: 'left' }}>
													Created On
												</span>
											</Title>

											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='23.676'
													height='26.306'
													viewBox='0 0 23.676 26.306'
												>
													<path
														id='Icon_material-date-range'
														data-name='Icon material-date-range'
														d='M12.392,14.838H9.761v2.631h2.631Zm5.261,0H15.022v2.631h2.631Zm5.261,0H20.284v2.631h2.631Zm2.631-9.207H24.23V3H21.6V5.631H11.077V3H8.446V5.631H7.131A2.619,2.619,0,0,0,4.513,8.261L4.5,26.676a2.63,2.63,0,0,0,2.631,2.631H25.545a2.638,2.638,0,0,0,2.631-2.631V8.261A2.638,2.638,0,0,0,25.545,5.631Zm0,21.045H7.131V12.207H25.545Z'
														transform='translate(-4.5 -3)'
														fill='#e43c5c'
													/>
												</svg>

												<p className='text-16 ml-10'>
													{data !== undefined &&
														data.findEntry.createdAt !== undefined &&
														new Date(
															data.findEntry.createdAt
														).toLocaleDateString('en-US', DATE_OPTIONS)}
												</p>
											</div>
										</Card>
									</Col>

									<Col span={8} xs={24} sm={24} md={8}>
										<Card size='small' className='stat-card'>
											<Title level={5}>
												<span style={{ color: '#E33B5C' }}>
													Social Accounts
												</span>
											</Title>

											{data !== undefined && data.findEntry.twitter !== null && (
												<div
													style={{
														textAlign: 'left',
														marginTop: '5px',
													}}
												>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='22.346'
														height='18.524'
														viewBox='0 0 20.346 16.524'
													>
														<path
															id='Icon_awesome-twitter'
															data-name='Icon awesome-twitter'
															d='M18.254,7.5c.013.181.013.361.013.542A11.783,11.783,0,0,1,6.4,19.905,11.784,11.784,0,0,1,0,18.033a8.627,8.627,0,0,0,1.007.052A8.351,8.351,0,0,0,6.184,16.3a4.177,4.177,0,0,1-3.9-2.892,5.259,5.259,0,0,0,.788.065,4.41,4.41,0,0,0,1.1-.142A4.17,4.17,0,0,1,.826,9.242V9.19a4.2,4.2,0,0,0,1.885.529A4.176,4.176,0,0,1,1.42,4.142a11.853,11.853,0,0,0,8.6,4.364,4.707,4.707,0,0,1-.1-.955A4.174,4.174,0,0,1,17.131,4.7a8.21,8.21,0,0,0,2.647-1.007,4.159,4.159,0,0,1-1.833,2.3,8.359,8.359,0,0,0,2.4-.645A8.964,8.964,0,0,1,18.254,7.5Z'
															transform='translate(0 -3.381)'
															fill='#e43c5c'
														/>
													</svg>
													<Text className='ml-10 text-20'>
														{data.findEntry.twitter}
													</Text>
												</div>
											)}
											{data !== undefined && data.findEntry.reddit !== null && (
												<div
													style={{
														textAlign: 'left',
														marginTop: '5px',
													}}
												>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='22.346'
														height='18.524'
														viewBox='0 0 18.69 17.652'
													>
														<path
															id='Icon_awesome-reddit-alien'
															data-name='Icon awesome-reddit-alien'
															d='M17.925,9a2.1,2.1,0,0,0-1.493.626,10.124,10.124,0,0,0-5.4-1.666l1.091-4.916,3.474.78a1.547,1.547,0,0,0,1.544,1.544A1.564,1.564,0,1,0,15.75,3.11l-3.837-.851a.367.367,0,0,0-.433.28l-1.2,5.42A10.251,10.251,0,0,0,4.913,9.645a2.077,2.077,0,1,0-2.415,3.3,4.409,4.409,0,0,0-.1.973c0,3.3,3.719,5.976,8.284,5.976,4.585,0,8.3-2.675,8.3-5.976a3.758,3.758,0,0,0-.122-.989A2.074,2.074,0,0,0,17.925,9ZM5.677,13.155A1.554,1.554,0,1,1,7.241,14.7,1.558,1.558,0,0,1,5.677,13.155Zm8.442,3.683c-1.434,1.434-5.48,1.434-6.913,0a.367.367,0,0,1,0-.54.379.379,0,0,1,.52,0c1.1,1.123,4.727,1.142,5.87,0a.379.379,0,0,1,.52,0A.364.364,0,0,1,14.119,16.838ZM14.088,14.7a1.554,1.554,0,1,1,1.564-1.544A1.547,1.547,0,0,1,14.088,14.7Z'
															transform='translate(-1.319 -2.243)'
															fill='#e43c5c'
														/>
													</svg>
													<Text className='ml-10 text-20'>
														{data.findEntry.reddit}
													</Text>
												</div>
											)}
											{data !== undefined && data.findEntry.telegram !== null && (
												<div
													style={{
														textAlign: 'left',
														marginTop: '5px',
													}}
												>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='17.935'
														height='17.936'
														viewBox='0 0 17.935 17.936'
													>
														<path
															id='Icon_awesome-telegram'
															data-name='Icon awesome-telegram'
															d='M8.968.563A8.968,8.968,0,1,0,17.935,9.53,8.966,8.966,0,0,0,8.968.563Zm4.4,6.144L11.9,13.642c-.108.492-.4.611-.81.38L8.848,12.369,7.767,13.41a.566.566,0,0,1-.452.221l.159-2.282L11.629,7.6c.181-.159-.04-.25-.278-.09L6.216,10.738,4,10.047c-.481-.152-.492-.481.1-.712L12.75,6c.4-.145.752.1.622.705Z'
															transform='translate(0 -0.563)'
															fill='#e43c5c'
														/>
													</svg>
													<Text className='ml-10 text-20'>
														{data.findEntry.telegram}
													</Text>
												</div>
											)}

											{data !== undefined && data.findEntry.discord !== null && (
												<div
													style={{
														textAlign: 'left',
														marginTop: '5px',
													}}
												>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='16.68'
														height='19.063'
														viewBox='0 0 16.68 19.063'
													>
														<path
															id='Icon_awesome-discord'
															data-name='Icon awesome-discord'
															d='M11.066,9.055A.976.976,0,1,1,10.094,8,1.015,1.015,0,0,1,11.066,9.055ZM6.615,8a1.062,1.062,0,0,0,0,2.116,1.015,1.015,0,0,0,.972-1.058A1.009,1.009,0,0,0,6.615,8ZM16.68,1.963v17.1c-2.4-2.122-1.633-1.42-4.423-4.013l.505,1.763H1.954A1.959,1.959,0,0,1,0,14.85V1.963A1.959,1.959,0,0,1,1.954,0H14.726A1.959,1.959,0,0,1,16.68,1.963ZM13.963,11a12.764,12.764,0,0,0-1.373-5.557,4.713,4.713,0,0,0-2.678-1l-.133.153A6.343,6.343,0,0,1,12.153,5.8a8.1,8.1,0,0,0-7.12-.276C4.68,5.69,4.47,5.8,4.47,5.8A6.43,6.43,0,0,1,6.977,4.556l-.1-.114a4.713,4.713,0,0,0-2.678,1A12.764,12.764,0,0,0,2.831,11a3.456,3.456,0,0,0,2.907,1.449s.353-.429.639-.791a2.964,2.964,0,0,1-1.668-1.125c.14.1.371.225.391.238a6.948,6.948,0,0,0,5.948.334,5.454,5.454,0,0,0,1.1-.562,3.008,3.008,0,0,1-1.725,1.134c.286.362.629.772.629.772A3.484,3.484,0,0,0,13.963,11Z'
															fill='#e43c5c'
														/>
													</svg>
													<Text className='ml-10 text-20'>
														{data.findEntry.discord}
													</Text>
												</div>
											)}

											{data !== undefined && data.findEntry.instagram !== null && (
												<div
													style={{
														textAlign: 'left',
														marginTop: '5px',
													}}
												>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='16.973'
														height='16.969'
														viewBox='0 0 16.973 16.969'
													>
														<path
															id='Icon_awesome-instagram'
															data-name='Icon awesome-instagram'
															d='M8.483,6.372a4.351,4.351,0,1,0,4.351,4.351A4.344,4.344,0,0,0,8.483,6.372Zm0,7.179a2.828,2.828,0,1,1,2.828-2.828A2.834,2.834,0,0,1,8.483,13.551Zm5.543-7.357a1.015,1.015,0,1,1-1.015-1.015A1.012,1.012,0,0,1,14.026,6.194Zm2.881,1.03a5.022,5.022,0,0,0-1.371-3.555A5.055,5.055,0,0,0,11.982,2.3c-1.4-.08-5.6-.08-7,0A5.048,5.048,0,0,0,1.425,3.664,5.038,5.038,0,0,0,.054,7.22c-.08,1.4-.08,5.6,0,7a5.022,5.022,0,0,0,1.371,3.555,5.061,5.061,0,0,0,3.555,1.371c1.4.08,5.6.08,7,0a5.022,5.022,0,0,0,3.555-1.371,5.055,5.055,0,0,0,1.371-3.555c.08-1.4.08-5.6,0-7Zm-1.81,8.5a2.864,2.864,0,0,1-1.613,1.613c-1.117.443-3.768.341-5,.341s-3.889.1-5-.341a2.864,2.864,0,0,1-1.613-1.613c-.443-1.117-.341-3.768-.341-5s-.1-3.889.341-5A2.864,2.864,0,0,1,3.481,4.107c1.117-.443,3.768-.341,5-.341s3.889-.1,5,.341A2.864,2.864,0,0,1,15.1,5.72c.443,1.117.341,3.768.341,5S15.541,14.611,15.1,15.724Z'
															transform='translate(0.005 -2.238)'
															fill='#e43c5c'
														/>
													</svg>
													<Text className='ml-10 text-20'>
														{data.findEntry.instagram}
													</Text>
												</div>
											)}
										</Card>
									</Col>
									<Col span={8} xs={24} sm={24} md={8}>
										<Card size='small' className='stat-card'>
											<Title level={5}>
												<span style={{ color: '#E33B5C' }}>Referred Users</span>
											</Title>

											<Text className='text-20'>
												{data !== undefined &&
													data.findEntry.referred !== undefined &&
													data.findEntry.referred.length}
											</Text>
											<Title level={5}>
												<span style={{ color: '#E33B5C' }}>Referred By</span>
											</Title>

											<Text className='text-20'>
												{data !== undefined &&
													data.findEntry.ref !== undefined &&
													data.findEntry.ref}
											</Text>
										</Card>
									</Col>
								</Row>
							</div>
						</section>
					)}

					{data?.findEntry === null && (
						<section
							className='stats-section'
							id='stats-section'
							ref={statsSection}
						>
							<div className='container stats-container'>
								<Row>
									<Col span={24} sm={24} md={24}>
										<Title level={5} className='newsroom-main-title'>
											No submission corresponding to that email was found.
											Please record your airdrop entry first.
										</Title>
										<Title level={5} className='newsroom-main-title'>
											<span style={{ color: '#E33B5C' }}>
												You will be redirected in {countdown}{' '}
												{countdown === 1 ? 'second' : 'seconds'}...
											</span>{' '}
										</Title>
									</Col>
								</Row>
							</div>
						</section>
					)}
				</Content>
			</Layout>
		</div>
	)
}
