import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'

import {
	ApolloProvider,
	ApolloClient,
	createHttpLink,
	InMemoryCache,
} from '@apollo/client'
const { REACT_APP_GRAPHQL_ENDPOINT: GQL_ENDPOINT } = process.env

const httpLink = createHttpLink({
	uri: GQL_ENDPOINT,
})

const client = new ApolloClient({
	link: httpLink,
	cache: new InMemoryCache(),
	onError: (e) => {
		console.log('Error')
		console.log(e)
	},
	// fetchOptions: {
	// 	mode: 'no-cors',
	// },
})

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<App />
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
