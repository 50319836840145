import React, { useState } from 'react'

import { Drawer, Button, Row, Col, Typography } from 'antd'
import { HashLink } from 'react-router-hash-link'
import { NavLink } from 'react-router-dom'
import Accordion from './Accordion'
import Icon from './Icon'

import logo from '../assets/logo_svg.svg'

const { Title } = Typography

function AppHeader() {
	const [visible, setVisible] = useState(false)

	const showDrawer = () => {
		setVisible(true)
	}

	const onClose = (e) => {
		e.preventDefault()
		setVisible(false)
	}

	const scrollWithOffset = (el) => {
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
		const yOffset = -100
		window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
	}

	return (
		<div className='container'>
			<div className='header'>
				<div
					className='logo'
					style={{ marginTop: 'auto', marginBottom: 'auto' }}
				>
					<NavLink to='/'>
						<img
							src={logo}
							alt='cherry logo'
							style={{ width: '170px', height: '45px', paddingBottom: '5px' }}
						/>
					</NavLink>
				</div>

				<div>
					<Button className='bg-transparent shadow-none' onClick={showDrawer}>
						<Icon name='hamburger' width='32' height='32' />
					</Button>
					<Drawer
						className='header-drawer'
						placement='right'
						closable={false}
						onClose={onClose}
						visible={visible}
						width={800}
					>
						<div className='close'>
							<Button onClick={onClose}>
								<Icon name='x' width='20' height='20' />
							</Button>
						</div>
						<div className='container-fluid menu-section'>
							<Row className='menu-desktop-hidden'>
								<Accordion>
									<Accordion.Item className='faq-item w-100 h-100'>
										<Accordion.Toggle
											tag='div'
											toggleIcon={<Icon name='caret' />}
										>
											<NavLink
												to='/'
												exact={true}
												activeClassName='nav-active'
												className='link anchor-header'
												onClick={() => setVisible(false)}
											>
												<Icon name='house' />
												<Title level={3}>Home</Title>
											</NavLink>
										</Accordion.Toggle>
										<Accordion.Collapse>
											<ul className='nav-links'>
												<li>
													<HashLink
														smooth
														to='/#vision'
														onClick={() => setVisible(false)}
														scroll={(el) => scrollWithOffset(el)}
													>
														Vision
													</HashLink>
												</li>
												<li>
													<HashLink
														smooth
														to='/#chain'
														onClick={() => setVisible(false)}
														scroll={(el) => scrollWithOffset(el)}
													>
														Chain
													</HashLink>
												</li>
												<li>
													<HashLink
														smooth
														to='/#ecosystem'
														onClick={() => setVisible(false)}
														scroll={(el) => scrollWithOffset(el)}
													>
														Ecosystem
													</HashLink>
												</li>
												<li>
													<HashLink
														smooth
														to='/#nft'
														onClick={() => setVisible(false)}
														scroll={(el) => scrollWithOffset(el)}
													>
														NFT's
													</HashLink>
												</li>
												<li>
													<HashLink
														smooth
														to='/#governance'
														onClick={() => setVisible(false)}
														scroll={(el) => scrollWithOffset(el)}
													>
														Governance
													</HashLink>
												</li>
												<li>
													<HashLink
														smooth
														to='/#community'
														onClick={() => setVisible(false)}
														scroll={(el) => scrollWithOffset(el)}
													>
														Community
													</HashLink>
												</li>
												<li>
													<HashLink
														smooth
														to='/#roadmap'
														onClick={() => setVisible(false)}
														scroll={(el) => scrollWithOffset(el)}
													>
														Roadmap
													</HashLink>
												</li>
												<li>
													<HashLink
														smooth
														to='/#labs'
														onClick={() => setVisible(false)}
														scroll={(el) => scrollWithOffset(el)}
													>
														Labs
													</HashLink>
												</li>
											</ul>
										</Accordion.Collapse>
									</Accordion.Item>
								</Accordion>
							</Row>

							<Row className='menu-desktop-hidden'>
								<Accordion>
									<Accordion.Item className='faq-item w-100 h-100'>
										<Accordion.Toggle
											tag='div'
											toggleIcon={<Icon name='caret' />}
										>
											<NavLink
												to='/token'
												activeClassName='nav-active'
												className='link anchor-header'
												onClick={() => setVisible(false)}
											>
												<Icon name='token' />
												<Title level={3}>Token</Title>
											</NavLink>
										</Accordion.Toggle>
										<Accordion.Collapse>
											<ul className='nav-links'>
												<li>
													<HashLink
														smooth
														to='/token#interoperability'
														onClick={() => setVisible(false)}
														scroll={(el) => scrollWithOffset(el)}
													>
														Interoperability
													</HashLink>
												</li>
												<li>
													<HashLink
														smooth
														to='/token#utility'
														onClick={() => setVisible(false)}
														scroll={(el) => scrollWithOffset(el)}
													>
														Utility
													</HashLink>
												</li>
												<li>
													<HashLink
														smooth
														to='/token#cherrynomics'
														onClick={() => setVisible(false)}
														scroll={(el) => scrollWithOffset(el)}
													>
														Cherrynomics
													</HashLink>
												</li>
												<li>
													<HashLink
														smooth
														to='/token#distribution'
														onClick={() => setVisible(false)}
														scroll={(el) => scrollWithOffset(el)}
													>
														Distribution
													</HashLink>
												</li>
												<li>
													<HashLink
														smooth
														to='/token#vesting'
														onClick={() => setVisible(false)}
														scroll={(el) => scrollWithOffset(el)}
													>
														Vesting Schedule
													</HashLink>
												</li>
												<li>
													<HashLink
														smooth
														to='/token#cherry-treasury'
														onClick={() => setVisible(false)}
														scroll={(el) => scrollWithOffset(el)}
													>
														Treasury
													</HashLink>
												</li>
											</ul>
										</Accordion.Collapse>
									</Accordion.Item>
								</Accordion>
							</Row>

							<Row className='menu-mobile-hidden'>
								<NavLink
									to='/'
									exact={true}
									activeClassName='nav-active'
									onClick={() => setVisible(false)}
									className='link anchor-header'
								>
									<Icon name='house' />
									<Title level={3}>Home</Title>
								</NavLink>
								<ul className='nav-links'>
									<li>
										<HashLink
											smooth
											to='/#vision'
											onClick={() => setVisible(false)}
											scroll={(el) => scrollWithOffset(el)}
										>
											Vision
										</HashLink>
									</li>
									<li>
										<HashLink
											smooth
											to='/#chain'
											onClick={() => setVisible(false)}
											scroll={(el) => scrollWithOffset(el)}
										>
											Chain
										</HashLink>
									</li>
									<li>
										<HashLink
											smooth
											to='/#ecosystem'
											onClick={() => setVisible(false)}
											scroll={(el) => scrollWithOffset(el)}
										>
											Ecosystem
										</HashLink>
									</li>
									<li>
										<HashLink
											smooth
											to='/#nft'
											onClick={() => setVisible(false)}
											scroll={(el) => scrollWithOffset(el)}
										>
											NFT
										</HashLink>
									</li>
									<li>
										<HashLink
											smooth
											to='/#governance'
											onClick={() => setVisible(false)}
											scroll={(el) => scrollWithOffset(el)}
										>
											Governance
										</HashLink>
									</li>
									<li>
										<HashLink
											smooth
											to='/#community'
											onClick={() => setVisible(false)}
											scroll={(el) => scrollWithOffset(el)}
										>
											Community
										</HashLink>
									</li>
									<li>
										<HashLink
											smooth
											to='/#roadmap'
											onClick={() => setVisible(false)}
											scroll={(el) => scrollWithOffset(el)}
										>
											Roadmap
										</HashLink>
									</li>
									<li>
										<HashLink
											smooth
											to='/#labs'
											onClick={() => setVisible(false)}
											scroll={(el) => scrollWithOffset(el)}
										>
											Labs
										</HashLink>
									</li>
								</ul>
							</Row>

							<Row className='menu-mobile-hidden'>
								<NavLink
									to='/token'
									activeClassName='nav-active'
									onClick={() => setVisible(false)}
									className='link anchor-header'
								>
									<Icon name='token' />
									<Title level={3}>Token</Title>
								</NavLink>
								<ul className='nav-links' style={{ maxHeight: '150px' }}>
									<li>
										<HashLink
											smooth
											to='/token#interoperability'
											onClick={() => setVisible(false)}
											scroll={(el) => scrollWithOffset(el)}
										>
											Interoperability
										</HashLink>
									</li>
									<li>
										<HashLink
											smooth
											to='/token#utility'
											onClick={() => setVisible(false)}
											scroll={(el) => scrollWithOffset(el)}
										>
											Utility
										</HashLink>
									</li>
									<li>
										<HashLink
											smooth
											to='/token#cherrynomics'
											onClick={() => setVisible(false)}
											scroll={(el) => scrollWithOffset(el)}
										>
											Cherrynomics
										</HashLink>
									</li>

									<li>
										<HashLink
											smooth
											to='/token#distribution'
											onClick={() => setVisible(false)}
											scroll={(el) => scrollWithOffset(el)}
										>
											Distribution
										</HashLink>
									</li>
									<li>
										<HashLink
											smooth
											to='/token#vesting'
											onClick={() => setVisible(false)}
											scroll={(el) => scrollWithOffset(el)}
										>
											Vesting Schedule
										</HashLink>
									</li>
									<li>
										<HashLink
											smooth
											to='/token#cherry-treasury'
											onClick={() => setVisible(false)}
											scroll={(el) => scrollWithOffset(el)}
										>
											Treasury
										</HashLink>
									</li>
								</ul>
							</Row>

							<Row>
								<NavLink
									to='/news'
									activeClassName='nav-active'
									onClick={() => setVisible(false)}
									className='link anchor-header'
								>
									<Icon name='newsroom' />
									<Title level={3}>Newsroom</Title>
								</NavLink>
							</Row>

							<Row>
								<a
									href='https://docs.cherry.network/faq'
									onClick={() => setVisible(false)}
									className='link anchor-header'
									target='_blank'
									rel='noreferrer'
								>
									<Icon name='faq' />
									<Title level={3}>FAQ</Title>
								</a>
							</Row>

							<Row>
								<a
									href='https://docs.cherry.network/'
									onClick={() => setVisible(false)}
									className='link anchor-header'
									target='_blank'
									rel='noreferrer'
								>
									<Icon name='documentation' />
									<Title level={3}>Documentation</Title>
								</a>
							</Row>
						</div>

						{/* <NavLink to='/'>
							<Button
								className='button text-white bg-primary start-your-page-button'
								onClick={onClose}
							>
								Start Your Page
							</Button>
						</NavLink> */}
						<div className='container-fluid social-section'>
							<Row>
								<Col span={24}>
									<Title level={3}>Connect</Title>
								</Col>
							</Row>
							<Row
								justify='middle'
								align='start'
								style={{ marginTop: '1rem' }}
								gutter={[10, 20]}
							>
								<Col span={12} xs={12} sm={12} md={12} className='mt-10'>
									<a
										href='https://twitter.com/Cherry_Network'
										target='_blank'
										rel='noreferrer'
										className='link'
										style={{ fontSize: '18px' }}
									>
										<Icon name='twitter' width='2rem' height='2rem' />
										Twitter
									</a>
								</Col>
								<Col span={12} xs={12} sm={12} md={12} className='mt-10'>
									<a
										href='https://t.me/cherrynetwork'
										target='_blank'
										rel='noreferrer'
										className='link text-20'
										style={{ fontSize: '18px' }}
									>
										<Icon name='telegram' width='2rem' height='2rem' />
										Telegram
									</a>
								</Col>
								<Col span={12} xs={12} sm={12} md={12} className='mt-10'>
									<a
										href='https://discord.gg/KAwC9aafWa'
										target='_blank'
										rel='noreferrer'
										className='link text-20'
										style={{ fontSize: '18px' }}
									>
										<Icon name='discord' width='2rem' height='2rem' />
										Discord
									</a>
								</Col>
								<Col span={12} xs={12} sm={12} md={12} className='mt-10'>
									<a
										href='https://forum.cherry.network'
										target='_blank'
										rel='noreferrer'
										className='link'
										style={{ fontSize: '18px' }}
									>
										<Icon name='messenger' width='2rem' height='2rem' />
										Forum
									</a>
								</Col>
							</Row>
						</div>
					</Drawer>
				</div>
			</div>
		</div>
	)
}

export default AppHeader
