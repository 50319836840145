import React from 'react'
import { Row, Col, Typography } from 'antd'
import Accordion from './Accordion'
import ReactMarkdown from 'react-markdown'
const { Title, Text } = Typography

export default function FAQ(props) {
	const { page } = props
	const faqs = [
		{
			question: 'What is Cherry?',
			answer:
				'💡 Cherry is a community of idealists and technologists organized within the [Cherry Decentralized Autonomous Organization](https://www.cherry.network/#governance), and united with the aim to improve the ability of artists and creators to express their art freely. \n\n 🛠️ We seek to accomplish this mission by developing and expanding on innovatives technological paradigms such as Blockchain, Decentralized Finance, and smart contracts.',
			pages: ['home', 'social', 'marketplace', 'governance'],
		},
		{
			question: 'Why Cherry?',
			answer:
				'🎨 We believe the ability to express art, no matter its content, is a necessary prerequisite for progress and prosperity. However, the legacy systems tend to censor and suppress art deemed "controversial" or "inconvenient." Emerging creators are abused and restricted with fees and high barriers of entry. \n\n 💥 As technologist we knew this didn\'t have to be the case. We took it upon ourselves to liberate artistic expression from the bondage of the past, for all of us, once and for all. \n\n',
			pages: ['home', 'social'],
		},
		{
			question: 'How can I get involved?',
			answer:
				'💬 Join the community on Discord, Telegram, Reddit, Twitter, and our [Governance forum](https://forum.cherry.network/). Reach out, share your insights and make new connections. An updated list of our social accounts can be found [on our website](https://cherry.network/). \n\n 🖥️ Are you a developer? Contribute to our open source projects. We plan to make all of the code available for anyone to review and contribute so we can make the [Cherry Vision](https://cherry.network/#vision) come true, together. \n',
			pages: ['home', 'social', 'labs', 'governance'],
		},
		{
			question: 'What is the Cherry Token ($CHER)?',
			answer:
				'💸 The Cherry Token, ticker code CHER, is the official currency of the Cherry Project. It is not issued by a government or bank. Instead it exists on the blockchain, it is decentralized and censorship resistant, and you can use it throughout the Cherry Ecosystem to access all of its features. [Learn more about the Cherry Token here.](https://www.cherry.network/token#cherrynomics)',
			pages: ['home', 'finance', 'marketplace'],
		},
		{
			question: 'Where can I find more resources about the Cherry Project?',
			answer:
				"ℹ️ We love to talk about the Cherry Project, and we've collected a lot of resources on our [website](https://cherry.network/) and [documentation hub](https://docs.cherry.network/). More articles and papers are being published every month, so keep an eye on our [social channels](https://cherry.network/#community) and [newsroom](https://cherry.network/news).",
			pages: ['home', 'finance', 'marketplace', 'labs', 'governance', 'social'],
		},
		{
			question: "What are the Cherry Project's plans for the future?",
			answer:
				"🔮 We are hard at work to launch and improve the Cherry Ecosystem's applications. You can consult our [Roadmap](https://cherry.network/#roadmap) as well as join the conversation, as it occurs, on our [Governance forum](https://forum.cherry.network/). \n\n ⁉️ The Cherry Project is run as a Decentralized Autonomous Organization, and the community is constantly involved in steering the efforts of the Cherry Project. We're excited what the Cherry DAO will decide next, within the scope of the Cherry Vision \n\n",
			pages: ['home', 'labs', 'governance'],
		},
		{
			question: 'What is the Cherry Decentralized Autonomous Organization?',
			answer:
				'📢 The Cherry Decentralized Autonomous Organization makes decision on the future of the Cherry Project. It also administers a monthly budget which it distributes to winning proposals. Join the conversation on [Cherry Governance](https://forum.cherry.network/).',
			pages: ['labs', 'governance'],
		},
		{
			question: 'What is Cherry Social?',
			answer:
				'🎨 [Cherry Social](https://app.cherry.network/) is a comprehensive self publishing tool for artists to curate a feed a interact with their community. The community can tip, or cherish the Artist, as well as purchase Commissions or items from the Artist\'s eShop. \n\n 💖 Fans "Cherish" the Artist when they follow and subscribe to their Social Page, often in exchange for a small recurring contribution. \n\n',
			pages: ['social'],
		},
		{
			question: 'What is Cherry Finance?',
			answer:
				'🐳 Cherry Finance is a platform for Decentralized Finance where Artists can obtain financial services such as insurance or loans, and investors can earn passive income on their capital.',
			pages: ['finance'],
		},
		{
			question: 'What is Cherry Marketplace?',
			answer:
				'💥 Cherry Marketplace aggregates all items offered for sale in the eShops of each Artist on Cherry Social, and makes then available in a single intuitive and integrated interface. eCommerce, just better.',
			pages: ['marketplace'],
		},
		{
			question: 'What is Cherry Labs?',
			answer:
				'⚗️ Cherry Labs is the outward facing arm of the Cherry Project. It facilitates partnerships, joint ventures and coordinates our corporate outreach efforts. It also organizes hackathons and issues grants for research and development to external teams. \n\n 🥼 Cherry Labs is overseen by the Technical Board, which in funded and mandated by the Cherry Decentralized Autonomous Organization. \n\n ',
			pages: ['labs'],
		},
		{
			question: 'How do I start publishing on Cherry Social?',
			answer:
				'🏗️ Cherry Social is currently undergoing development and is in a closed beta release. You can apply to become one of the first artists to create your Social Page, or simply sign up to the Newsletter and get notified of updates and releases.',
			pages: ['social'],
		},
		{
			question: 'How do I start selling on Cherry Marketpace?',
			answer:
				'🔨 Cherry Marketplace is currently undergoing development. You can apply to become one of the first Artists to list your products on the Cherry Marketplace, or simply sign up to the Newsletter and get notified of updates and releases.',
			pages: ['marketplace'],
		},
		{
			question: 'How do I ask for funding in Cherry Finance?',
			answer:
				'🏗️ Cherry Finance is currently undergoing development. You can apply to become one of the first Artists to obtain financing through Cherry Finance, or simply sign up to the Newsletter and get notified of updates and releases.',
			pages: ['finance'],
		},
		{
			question: 'How can I earn interest with Cherry Finance?',
			answer:
				'🔨 Cherry Finance is currently undergoing development. Sign up to the Newsletter and get notified of updates and releases.',
			pages: ['finance'],
		},
		{
			question: 'Where can I buy the Cherry Token (CHER)?',
			answer:
				'💰 The token sale is live on the Cherry Finance website. You can join today and get in early on our revolutionary new project.',
			pages: ['finance', 'marketplace'],
		},
		{
			question: 'What are Cherry Governance Keys?',
			answer:
				'🔑 There are many ways community members can participate in Cherry DAO governance. The Cherry Governance Key entitles holders to cast votes and directly affect the future of the Project.',
			pages: ['governance'],
		},
		{
			question: 'How do I apply for research grants?',
			answer:
				'🧪 Information about the processes and procedures about Cherry Labs grant program will be published shortly. Keep an eye on our [social channels](https://cherry.network/#community) and [newsroom](https://cherry.network/news) for updates.',
			pages: ['labs'],
		},
	]

	return (
		<Row
			justify='center'
			align='stretch'
			className='faq'
			id='faq'
			gutter={[60, 40]}
		>
			<Col lg={12}>
				<Title level={2} className='faq-header'>
					Frequently Asked Questions
				</Title>
				<Text className='text-body'>
					The Cherry Ecosystem is vast and filled with innovative ideas and
					bleeding edge technologies. While we strive to make everything as
					accessible and user friendly as possible, we don't expect you to
					understand everything immediately. Find our community's frequently
					asked questions in{' '}
					<a
						href='http://docs.cherry.network/'
						target='_blank'
						rel='noreferrer'
						className='link'
						style={{ color: '#D91B3C' }}
					>
						Cherry Docs.
					</a>
				</Text>
			</Col>
			<Col xs={24} sm={24} lg={12}>
				<Accordion>
					{faqs
						.filter((faq) => {
							if (page && !faq.pages.includes(page)) {
								return false
							}
							return true
						})
						.map((faq, index) => (
							<div style={{ marginBottom: '2rem' }} key={index}>
								<Accordion.Item key={index} className='faq-item h-100'>
									<Accordion.Toggle tag='div' eventKey={index}>
										{/* <p className="rounded-number">{index + 1}</p> */}
										<Title level={5} className='faq-question text-left bold'>
											{faq.question}
										</Title>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey={index}>
										<div>
											<ReactMarkdown children={faq.answer} />
										</div>
									</Accordion.Collapse>
								</Accordion.Item>
							</div>
						))}
				</Accordion>
			</Col>
		</Row>
	)
}
