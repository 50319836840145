import React, { useContext, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CTX } from '../Store'
import styles from './styles.module.scss'

function Collapse(props) {
	const { children, eventKey } = props
	const [style, setStyle] = useState(null)
	const id = 'accordionCollapse' + (eventKey + 1)

	const [activeIndex] = useContext(CTX)

	// * "useLayoutEffect - use this if you need to perform measurements" https://kentcdodds.com/blog/useeffect-vs-uselayouteffect
	useLayoutEffect(() => {
		const element = document.getElementById(id)
		if (eventKey === activeIndex) {
			const height = element.scrollHeight
			setStyle({ height })
		} else {
			setStyle(null)
		}
	}, [eventKey, activeIndex, id])

	return (
		<div
			id={id}
			className={`${styles.accordionCollapse} accordion-collapse`}
			style={style}
		>
			<p>{children}</p>
		</div>
	)
}

Collapse.propTypes = {
	children: PropTypes.node.isRequired,
	eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Collapse
