import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

function Item({ children, className }) {
	return (
		<div className={`${styles.accordionItem} ${className}`}>{children}</div>
	)
}

Item.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Item
