import React from 'react'

import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom'

import { Layout } from 'antd'

import AppHeader from './components/Header'
import AppFooter from './components/Footer'

import HomePage from './pages/Homepage/Homepage'
import TokenSale from './pages/TokenSale/TokenSale'
import Newsroom from './pages/Newsroom/Newsroom'
import Cherry503 from './pages/Cherry503/Cherry503'
import Referrals from './pages/Referrals/Referrals'

import ScrollToTop from './components/ScrollToTop'

import 'antd/dist/antd.css'
import './assets/scss/main.scss'
import BrandAssets from './pages/BrandAssets/BrandAssets'
import NewsDetail from './pages/Newsroom/NewsDetail'

const { Header, Footer } = Layout

function App() {
	return (
		<Router>
			<ScrollToTop />
			<Header>
				<AppHeader />
			</Header>
			<Switch>
				<Route path='/' exact>
					<HomePage />
				</Route>

				<Route path='/token'>
					<TokenSale />
				</Route>

				<Route path='/news'>
					<Newsroom />
				</Route>

				{/* <Route path='/airdrop'>
					<Referrals />
				</Route> */}

				<Route path='/brand'>
					<BrandAssets />
				</Route>

				<Route path='/news-article/welcome-to-cherry'>
					<NewsDetail />
				</Route>

				<Route path='/404'>
					<Cherry503 />
				</Route>

				<Redirect to='/404' />
			</Switch>
			<Footer>
				<AppFooter />
			</Footer>
		</Router>
	)
}

export default App
