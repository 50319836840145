import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Layout, Typography } from 'antd'

const { Content } = Layout
const { Title, Paragraph } = Typography
const { REACT_APP_CDN_ENDPOINT: CDN } = process.env

export default function Cherry503() {
	return (
		<Layout className='main-layout page-503'>
			<Content>
				<section className='hero'>
					<div className='container'>
						<Row justify='space-around' align='middle' gutter={[60, 30]}>
							<Col lg={{ span: 12, order: 1 }} xs={{ order: 2 }}>
								<Title level={3} className='text-red'>
									404
								</Title>
								<Title level={1} className='title'>
									Page not found
								</Title>
								<Paragraph>
									Hey, looks like you reached a page with an invalid path.
									Please select from the links below to fix that. Thank you!
								</Paragraph>
								<div className='helpful-links'>
									<Link to='/'>Need help?</Link>
									<Link to='/'>How it works</Link>
									<Link to='/'>Help Center</Link>
									<Link to='/'>Contact Us</Link>
								</div>
							</Col>
							<Col lg={{ span: 12, order: 2 }} xs={{ order: 1 }}>
								<div className='image-wrap'>
									<img src={`${CDN}/landing/503/503.svg`} alt='404' />
								</div>
							</Col>
						</Row>
					</div>
				</section>
			</Content>
		</Layout>
	)
}
