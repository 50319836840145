import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CTX } from '../Store'
import styles from './styles.module.scss'

function Toggle(props) {
	const { children, tag: Component, eventKey } = props

	const [activeIndex, setActiveIndex] = useContext(CTX)

	const mainClasses = classNames(styles.accordionToggle, {
		[styles.active]: styles.active && eventKey === activeIndex,
	})

	const handleClick = (eventKey) => {
		setActiveIndex(eventKey === activeIndex ? null : eventKey)
	}

	return (
		<Component
			className={`${mainClasses} accordion-toggle`}
			onClick={() => handleClick(eventKey)}
		>
			{children}{' '}
			{props.toggleIcon ? (
				activeIndex === eventKey ? (
					<span className='toggle-icon toggled'>{props.toggleIcon}</span>
				) : (
					<span className='toggle-icon'>{props.toggleIcon}</span>
				)
			) : activeIndex === eventKey ? (
				<span className='toggle-icon'>-</span>
			) : (
				<span className='toggle-icon'>+</span>
			)}
		</Component>
	)
}

Toggle.propTypes = {
	children: PropTypes.node.isRequired,
	tag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Toggle.defaultProps = {
	tag: 'h3',
}

export default Toggle
