import React, { useState } from 'react'

import NewsCard from './NewsCard'
import Icon from '../../components/Icon'

import { Col, Row, Button, Typography, Layout, Input } from 'antd'
const { REACT_APP_CDN_ENDPOINT: CDN } = process.env

const { Content } = Layout
const { Text, Title } = Typography

export default function Newsroom(props) {
	const [email, setEmail] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	return (
		<div>
			<Layout className='newsroom-layout'>
				<Content className='newsroom-content'>
					<section className='news-top-main-section' id='news-top-main-section'>
						<div className='container main-container'>
							<Row justify='space-between' align='middle' gutter={[40, 50]}>
								<Col md={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
									<div className='top-left-container'>
										<Title level={1} className='newsroom-main-title'>
											The{' '}
											<span style={{ color: '#E33B5C' }}>Cherry Ecosystem</span>{' '}
											in the news.
										</Title>
										<Row>
											<Col span={24} xs={24} md={24} sm={24}>
												<Text className='text-20'>
													<span className='bold'>Press</span> kit &{' '}
													<span className='bold'>media</span> assets{' '}
													<a
														href='https://www.cherry.network'
														target='_blank'
														rel='noreferrer'
														className='external-link primary'
													>
														Available here
													</a>
												</Text>
											</Col>
											<Col span={24} md={14} sm={22} xs={22}>
												<form className='input-email'>
													<Input
														type='email'
														value={email}
														onChange={(e) => setEmail(e.target.value)}
														placeholder={
															errorMessage === ''
																? 'Search news about Cherry'
																: errorMessage
														}
													></Input>
													<Button onClick={null}>
														<Icon name='arrow-right-icon' />
													</Button>
												</form>
											</Col>
										</Row>
									</div>
								</Col>
								<Col md={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
									<img
										src={`${CDN}/landing/newsroom/in_the_news.svg`}
										alt='background'
										className='bg-image'
									/>
								</Col>
							</Row>
						</div>
					</section>

					<section className='newsfeed-section' id='newsfeed-section'>
						<div className='newsfeeds'>
							<Row gutter={[10, 40]}>
								<Col span={24} sm={24} md={24}>
									<NewsCard
										title='Cherry Progress Report: July 2021'
										description='Dear Cherry Community, So much has been going on behind the scenes: Cherry Chain is approaching testnet viability, the first smart contracts are being implemented and tested, and our Ecosystem is ...'
										externalLink='https://cherryproject.medium.com/cherry-progress-report-july-2021-416e74c55a8b'
										rowSpan='3'
										date='5 AUG'
										imageSrc='https://miro.medium.com/max/3892/1*INjvv-mrlxs3fx4-LQBytQ.png'
									/>
								</Col>

								<Col span={24} sm={24} md={24}>
									<NewsCard
										title='Cherry Airdrop: How to join and claim Cherry Tokens $CHER'
										description='As the Cherry Project approaches our Initial Decentralized Exchange Offering sale in the coming weeks, we wanted to reward our enthusiastic community for the progress we’ve made so far.'
										externalLink='https://cherryproject.medium.com/cherry-airdrop-how-to-join-and-claim-cherry-tokens-cher-51752bafd331'
										rowSpan='3'
										date='27 JULY'
										imageSrc='https://miro.medium.com/max/3892/1*CQexNemz-8KsAe6Afbcf7A.png'
									/>
								</Col>

								<Col span={24} sm={24} md={24}>
									<NewsCard
										title='Cherry & the Internet of Blockchains'
										description='The Cherry Project aims to empower artists to create art unbounded by traditional conventions, free from censorship, and independent from the whims of the powers that be...'
										externalLink='https://cherryproject.medium.com/cherry-the-internet-of-blockchains-1a01a2dcc8c0'
										rowSpan='3'
										date='14 JULY'
										imageSrc='https://miro.medium.com/max/10000/1*muIkgEAKKs9nYvquiW3slg@2x.png'
									/>
								</Col>

								<Col span={24} sm={24} md={24}>
									<NewsCard
										title='Cherry Announces New Blockchain Platform for Creators'
										description='Cherry offers blockchain technologies for Creators to protect, publish, and monetize their art. After 9 months of development the team announces Cherry Project.'
										externalLink='https://www.einpresswire.com/article/543708164/cherry-announces-new-blockchain-platform-for-creators'
										rowSpan='3'
										date='14 JUN'
										imageSrc='https://assets.cherry.network/landing/newsroom/thumbnail_1.png'
									/>
								</Col>
								{/* <Col span={24} sm={24} md={24}>
									<NewsCard
										title='Welcome to Cherry. The Creators Network!'
										description='Welcome to Cherry — the Creators Network. Cherry is the world’s first smart contract-enabled media-optimized layer one blockchain network...'
										externalLink='https://www.cherry.network/news-article/welcome-to-cherry'
										rowSpan='3'
										date='23 JUN'
										imageSrc='https://assets.cherry.network/cherry_introduction.png'
									/>
								</Col> */}
							</Row>
						</div>
					</section>
				</Content>
			</Layout>
		</div>
	)
}
