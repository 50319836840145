import React from 'react'
import { Row, Col, Card, Typography } from 'antd'

const { Title, Paragraph } = Typography

const NewsCard = ({
	imageSrc,
	title,
	description,
	rowSpan,
	date,
	externalLink,
}) => {
	return (
		<div>
			<Card hoverable className='newscard'>
				<Row>
					<Col span={10} md={10} xs={24} sm={24}>
						<div className='image-container'>
							<img src={imageSrc} alt='new article' />
							<div className='date-card'>
								<span className='center-text'>{date}</span>
							</div>
						</div>
					</Col>
					<Col span={14} md={14} xs={24} sm={24}>
						<div className='news-detail'>
							<Title level={3} style={{ color: '#E33B5C' }}>
								{title}
							</Title>
							<Paragraph className='text-22'>{description}</Paragraph>

							<a
								href={externalLink}
								target='_blank'
								rel='noreferrer'
								className='external-link'
							>
								Read the whole article
								<svg
									style={{ marginLeft: '10px' }}
									xmlns='http://www.w3.org/2000/svg'
									width='22.268'
									height='9.286'
									viewBox='0 0 22.268 9.286'
								>
									<g
										id='Group_2271'
										data-name='Group 2271'
										transform='translate(-699.723 -466.91)'
									>
										<path
											id='Path_6313'
											data-name='Path 6313'
											d='M0,0,3.936,3.936,7.872,0'
											transform='translate(717.554 475.489) rotate(-90)'
											fill='none'
											stroke='#e33b5c'
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='1'
										/>
										<path
											id='Path_6314'
											data-name='Path 6314'
											d='M0,0V21.268'
											transform='translate(700.223 471.553) rotate(-90)'
											fill='none'
											stroke='#e33b5c'
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='1'
										/>
									</g>
								</svg>
							</a>
						</div>
					</Col>
				</Row>
			</Card>
		</div>
	)
}

export default NewsCard
