import React, { useEffect, useState } from 'react'
import Icon from '../../components/Icon'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
// import Guage2 from './graphics/Guage2'
// import GuageMobile2 from './graphics/GuageMobile2'
import AreaChart from '../../components/Charts/AreaChart'
import PieChart from '../../components/Charts/PieChart'
import CherryGuage from '../../components/Charts/Guage'

// Components
import JoinWaitingListModal from '../../components/JoinWaitingListModal'
import { Col, Row, Button, Typography, Layout } from 'antd'

const { Content } = Layout
const { Title, Text, Paragraph } = Typography
const { REACT_APP_CDN_ENDPOINT: CDN, REACT_APP_TOKEN_SALE_DATE: TSD } =
	process.env

export default function TokenSale(props) {
	let location = useLocation()

	const targetTime = parseInt(new Date(TSD).getTime().toFixed(0))

	const [countDownEnd, setCountDownEnd] = useState(false)
	const [day, setDay] = useState(0)
	const [hour, setHour] = useState(0)
	const [minute, setMinute] = useState(0)
	const [second, setSecond] = useState(0)

	const [visible, setVisible] = useState(false)

	useEffect(() => {
		console.log(location.hash)

		if (location.hash === '#join-waitlist') {
			setVisible(true)
		}
	}, [location.hash])

	const openModal = () => {
		setVisible(true)
	}

	const closeModal = () => {
		setVisible(false)
	}

	useEffect(() => {
		setInterval(() => {
			let now = new Date().getTime()
			let timeleft = targetTime - now
			if (timeleft < 0) {
				setCountDownEnd(true)
			}
			// Calculating the days, hours, minutes and seconds left
			setDay(Math.floor(timeleft / (1000 * 60 * 60 * 24)))
			setHour(Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
			setMinute(Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)))
			setSecond(Math.floor((timeleft % (1000 * 60)) / 1000))
		}, 1000)
		return () => {
			console.log('cleanup')
		}
	}, [])

	return (
		<Layout className='token-sale-layout'>
			<Content className='token-sale'>
				<section className='cherry-public-sale-section' id='cherry-public-sale'>
					<img
						src={`${CDN}/landing/token-sale/curve_line.svg`}
						alt='background'
						className='bg-image'
					/>
					<div className='container main-container'>
						<Row justify='space-between' align='middle' gutter={[40, 50]}>
							<Col md={{ span: 12, order: 1 }} xs={{ span: 24, order: 1 }}>
								<div className='top-left-container'>
									<Title level={1} className='token-sale-main-title'>
										The{' '}
										<span style={{ color: '#E33B5C' }}>
											Cherry Token Public Sale
										</span>{' '}
										will begin in
									</Title>
									<div className='countdown'>
										<Row gutter={[5]}>
											<Col xs={11} sm={11} md={5}>
												<div>
													<Title level={2}>{day}</Title>
													Days
												</div>
											</Col>
											<Col xs={11} sm={11} md={5}>
												<div>
													<Title level={2}>{hour}</Title>
													Hours
												</div>
											</Col>
											<Col xs={11} sm={11} md={5}>
												<div>
													<Title level={2}>{minute}</Title>
													Minutes
												</div>
											</Col>
											<Col xs={11} sm={11} md={5}>
												<div>
													<Title level={2}>{second}</Title>
													Seconds
												</div>
											</Col>
										</Row>
									</div>
									<Row>
										<Col span={24} xs={24} md={24} sm={24}>
											<Text className='text-20'>
												Cherry Token{' '}
												<span style={{ fontWeight: 'bold' }}>($CHER)</span>{' '}
												powers the Cherry Ecosystem
											</Text>
											<br />
										</Col>
										<Col span={24} md={24} sm={24} xs={24}>
											<Button
												className='primary-button mt-10'
												type='button'
												onClick={openModal}
											>
												Join the waiting list
												<span className='arrow-button'>
													<svg
														className='arrow'
														xmlns='http://www.w3.org/2000/svg'
														width='24.564'
														height='12.761'
														viewBox='0 0 24.564 12.761'
													>
														<g
															id='Icon_feather-arrow-left'
															data-name='Icon feather-arrow-left'
															transform='translate(1 1.414)'
														>
															<path
																id='Path_156'
																data-name='Path 156'
																d='M7.5,18H30.064'
																transform='translate(-7.5 -13.034)'
																fill='none'
																stroke='#fff'
																strokeLinecap='round'
																strokeLinejoin='round'
																strokeWidth='2'
															/>
															<path
																id='Path_157'
																data-name='Path 157'
																d='M7.5,17.433l4.8-4.966L7.5,7.5'
																transform='translate(10.268 -7.5)'
																fill='none'
																stroke='#fff'
																strokeLinecap='round'
																strokeLinejoin='round'
																strokeWidth='2'
															/>
														</g>
													</svg>
												</span>
											</Button>
											<JoinWaitingListModal
												isVisible={visible}
												closeModal={closeModal}
											/>
										</Col>
									</Row>
								</div>
							</Col>
							<Col md={{ span: 12, order: 2 }} xs={{ span: 24, order: 2 }}>
								<div className='image-wrap'>
									{/* <Guage2 width='85%' height='85%' className='mobile-hidden' />
									<GuageMobile2
										width='85%'
										height='85%'
										className='desktop-hidden'
									/> */}
									<CherryGuage width='85%' />
								</div>
								<div className='accepted-payments'>
									<Paragraph className='accepted-here-text'>
										Accepted Here
									</Paragraph>

									<div className='accepted-payment-item'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='22.475'
											height='22.474'
											viewBox='0 0 22.475 22.474'
										>
											<g id='Layer_x0020_1' transform='translate(0)'>
												<g id='_1421344023328' transform='translate(0 0)'>
													<path
														id='Subtraction_1'
														data-name='Subtraction 1'
														d='M11.245,22.474a11.279,11.279,0,0,1-2.727-.336A11.236,11.236,0,0,1,7.582.613,11.15,11.15,0,0,1,11.229,0a11.275,11.275,0,0,1,2.725.336,11.236,11.236,0,0,1,.938,21.524A11.155,11.155,0,0,1,11.245,22.474ZM9.18,15.566h0c.3.081.62.165.982.255l-.5,2.017,1.233.307L11.4,16.1a8.765,8.765,0,0,0,1.613.177,2.573,2.573,0,0,0,2.737-1.842,2.158,2.158,0,0,0-1.133-2.99,1.972,1.972,0,0,0,1.574-1.81c.192-1.287-.594-2.188-2.472-2.836l.505-2.026-1.233-.307L12.5,6.44c-.327-.082-.663-.158-.988-.232l.495-1.985-1.232-.307L10.271,5.94,10.2,5.923c-.224-.052-.477-.11-.713-.169V5.748l-1.7-.425L7.456,6.641c.209.048.894.207.9.222a.658.658,0,0,1,.574.718L8.351,9.888a1.056,1.056,0,0,1,.128.041l-.023-.006L8.349,9.9,7.543,13.13a.453.453,0,0,1-.419.312.613.613,0,0,1-.146-.019c-.055,0-.888-.222-.9-.224L5.47,14.61l1.6.4c.178.044.354.09.524.135l.011,0,.344.089-.51,2.048,1.232.307.505-2.026Zm2.678-1.074h0a8.524,8.524,0,0,1-2.037-.347l-.241-.062.677-2.715c.084.021.186.044.3.07.774.174,2.215.5,2.693,1.272a1.1,1.1,0,0,1,.121.876C13.226,14.187,12.715,14.492,11.858,14.492Zm.553-4.033h0a6.65,6.65,0,0,1-1.642-.281l-.2-.053.614-2.462c.071.018.156.036.254.058.629.139,1.8.4,2.211,1.061a1.044,1.044,0,0,1,.11.831C13.616,10.175,13.164,10.459,12.411,10.459Z'
														transform='translate(0 0)'
														fill='#e33b5c'
													/>
												</g>
											</g>
										</svg>
									</div>
									<div className='accepted-payment-item'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='13.177'
											height='21.46'
											viewBox='0 0 13.177 21.46'
										>
											<g id='Layer_x0020_1' transform='translate(0 0)'>
												<g id='_1421394342400' transform='translate(0 0)'>
													<g id='Group_1' data-name='Group 1'>
														<path
															id='Path_4'
															data-name='Path 4'
															d='M383.644,0,383.5.489v14.19l.144.144,6.587-3.893Z'
															transform='translate(-377.057)'
															fill='#e33b5c'
														/>
														<path
															id='Path_5'
															data-name='Path 5'
															d='M6.587,0,0,10.929l6.587,3.893V0Z'
															fill='#e33b5c'
														/>
														<path
															id='Path_6'
															data-name='Path 6'
															d='M387.321,728.781l-.081.1v5.055l.081.237,6.591-9.282Z'
															transform='translate(-380.734 -712.712)'
															fill='#e33b5c'
														/>
														<path
															id='Path_7'
															data-name='Path 7'
															d='M6.587,734.172v-5.39L0,724.89Z'
															transform='translate(0 -712.712)'
															fill='#e33b5c'
														/>
														<path
															id='Path_8'
															data-name='Path 8'
															d='M392.07,479.217l6.587-3.893-6.587-2.994Z'
															transform='translate(-385.483 -464.395)'
															fill='#e33b5c'
														/>
														<path
															id='Path_9'
															data-name='Path 9'
															d='M0,475.324l6.587,3.893V472.33Z'
															transform='translate(0 -464.395)'
															fill='#e33b5c'
														/>
													</g>
												</g>
											</g>
										</svg>
									</div>
									<div className='accepted-payment-item'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='18.634'
											height='22.808'
											viewBox='0 0 18.634 22.808'
										>
											<g id='pivx-pivx-logo' transform='translate(0 0)'>
												<g
													id='Group_2'
													data-name='Group 2'
													transform='translate(0 0)'
												>
													<path
														id='Path_163'
														data-name='Path 163'
														d='M9.308,0,0,1.946v12.9c0,1.889,1.935,3.674,4.031,5.062a40.029,40.029,0,0,0,5.135,2.843l.151.057.151-.057c.378-.142,9.166-3.882,9.166-7.9V1.946Zm8.571,14.846c0,2.814-6.051,5.789-8.571,6.781l-.123-.047-.047-.019-.085-.038L9.005,21.5l-.085-.038-.028-.009-.1-.047L8.76,21.4l-.113-.047-.019-.009L8.505,21.3H8.5l-.142-.057H8.345A29.637,29.637,0,0,1,4.021,18.9l-.17-.113-.17-.123-.16-.113-.009-.009-.151-.113-.009-.009L3.2,18.3l-.009-.009-.151-.113-.019-.009-.142-.1-.019-.009-.132-.1-.019-.019-.123-.1-.019-.019-.113-.1-.028-.019-.1-.1-.028-.028-.1-.1-.028-.028-.1-.094L2.029,17.3l-.094-.094-.028-.038-.085-.094-.028-.038-.085-.094-.028-.038L1.6,16.811l-.038-.038L1.5,16.689l-.038-.047L1.4,16.556l-.038-.047L1.3,16.424l-.028-.047-.057-.085-.028-.057a.514.514,0,0,0-.047-.076L1.1,16.1a.283.283,0,0,1-.038-.076l-.028-.057c-.009-.028-.028-.047-.038-.076a.314.314,0,0,0-.028-.057.651.651,0,0,1-.028-.076c-.009-.028-.019-.038-.028-.066a.46.46,0,0,0-.028-.066.175.175,0,0,1-.019-.066c0-.019-.019-.047-.019-.066a.175.175,0,0,0-.019-.066.6.6,0,0,1-.019-.066C.8,15.336.8,15.317.792,15.289a.14.14,0,0,1-.009-.057c0-.019-.009-.047-.009-.076S.764,15.119.764,15.1a.351.351,0,0,0-.019-.085c-.009-.028,0-.028,0-.047V2.635L9.27,1.266h.075L17.87,2.635l.009,12.212Z'
														fill='#e33b5c'
													/>
													<path
														id='Path_164'
														data-name='Path 164'
														d='M141.058,193.79l-6.457,1.039-1.331.208V206.1c0,2.559,5.5,5.27,7.788,6.167a25.238,25.238,0,0,0,5.532-3.013c.132-.1.274-.208.4-.312a6.556,6.556,0,0,0,1.454-1.568,2.44,2.44,0,0,0,.406-1.266V195.037Zm-3.757,5.865h4.54v.945H137.3Zm8.854.444a3.516,3.516,0,0,1-3.71,3.683h-2.983V208.5h-1.18v-5.79h4a2.445,2.445,0,0,0,2.653-2.616,2.413,2.413,0,0,0-2.634-2.569h-1.246l-2.983.009v-1.077h4.361a3.482,3.482,0,0,1,3.719,3.636'
														transform='translate(-131.75 -191.58)'
														fill='#e33b5c'
													/>
													<path
														id='Path_165'
														data-name='Path 165'
														d='M815.4,0V1.266h.038l8.525,1.369v12.2c0,2.814-6.051,5.789-8.571,6.781V22.8l.151-.057c.378-.142,9.166-3.882,9.166-7.9V1.946Z'
														transform='translate(-806.091)'
														fill='#e33b5c'
													/>
													<path
														id='Path_166'
														data-name='Path 166'
														d='M257.835,203.773h-2.973v.557a12.271,12.271,0,0,0,1.6,1.086,16.124,16.124,0,0,0,7.382,1.945,2.44,2.44,0,0,0,.406-1.266V195.037l-7.788-1.247L250,194.829a14.942,14.942,0,0,0,3.682,8.415v-.529h4a2.445,2.445,0,0,0,2.653-2.616,2.413,2.413,0,0,0-2.634-2.569h-1.247l-2.983.009v-1.077h4.361a3.655,3.655,0,1,1,0,7.31m-1.378-4.118h.774v.945H252.7v-.945Z'
														transform='translate(-247.149 -191.58)'
														fill='#e33b5c'
													/>
												</g>
												<g
													id='Group_3'
													data-name='Group 3'
													transform='translate(0 0)'
												>
													<path
														id='Path_167'
														data-name='Path 167'
														d='M9.194,21.58l-.047-.019-.085-.028-.047-.019-.085-.038L8.9,21.467l-.1-.047L8.77,21.41l-.113-.047-.019-.009L8.515,21.3H8.5l-.142-.057H8.345a29.277,29.277,0,0,1-4.314-2.352l-.17-.113-.17-.132-.16-.113-.009-.009-.151-.113L3.361,18.4l-.151-.113L3.2,18.274l-.151-.113-.019-.009-.142-.1-.019-.009-.132-.1-.019-.018-.123-.1-.019-.019-.113-.1-.028-.019-.1-.1L2.3,17.538l-.1-.1-.028-.028-.1-.094-.028-.028-.094-.094-.028-.038-.085-.094L1.8,17.019l-.085-.094-.028-.038L1.6,16.811l-.038-.038L1.5,16.688l-.038-.047L1.4,16.556l-.038-.047L1.3,16.424l-.028-.047-.057-.085-.028-.047a.514.514,0,0,0-.047-.076L1.1,16.112l-.038-.076-.028-.057c-.009-.028-.028-.047-.038-.076a.314.314,0,0,0-.028-.057.651.651,0,0,1-.028-.076c-.009-.028-.019-.038-.028-.066a.46.46,0,0,0-.028-.066.137.137,0,0,1-.019-.066c0-.028-.019-.057-.019-.076a.175.175,0,0,0-.019-.066.6.6,0,0,1-.019-.066C.8,15.336.8,15.317.792,15.289a.14.14,0,0,1-.009-.057c0-.019-.009-.047-.009-.076S.764,15.119.764,15.1a.351.351,0,0,0-.019-.085c-.009-.028,0-.028,0-.047V2.635L9.27,1.266h.038V0L0,1.946v12.9c0,1.889,1.935,3.674,4.031,5.062a40.029,40.029,0,0,0,5.135,2.843l.151.057V21.627l-.123-.047'
														fill='#e33b5c'
													/>
													<path
														id='Path_168'
														data-name='Path 168'
														d='M141.058,295.477a12.271,12.271,0,0,1-1.6-1.086v4.174h-1.18V293.3a14.973,14.973,0,0,1-3.672-8.415l-1.331.208v11.059c0,2.559,5.5,5.27,7.788,6.167a25.237,25.237,0,0,0,5.532-3.013c.132-.1.274-.208.4-.312a6.556,6.556,0,0,0,1.454-1.568,16.517,16.517,0,0,1-7.382-1.955'
														transform='translate(-131.75 -281.641)'
														fill='#e33b5c'
													/>
												</g>
											</g>
										</svg>
									</div>
									<div className='accepted-payment-item'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='14.734'
											height='20.062'
											viewBox='0 0 14.734 20.062'
										>
											<g
												id='Layer_2'
												data-name='Layer 2'
												transform='translate(0 0)'
											>
												<path
													id='Path_15'
													data-name='Path 15'
													d='M9.517,20.062a4.165,4.165,0,0,1-3.135-1.034A3.194,3.194,0,0,1,5.4,16.8a1.478,1.478,0,0,1,.172-.737,1.275,1.275,0,0,1,.47-.471,1.64,1.64,0,0,1,1.464,0,1.254,1.254,0,0,1,.467.471,1.448,1.448,0,0,1,.179.737,1.407,1.407,0,0,1-.251.862,1.168,1.168,0,0,1-.6.436,1.627,1.627,0,0,0,.94.586,4.66,4.66,0,0,0,1.282.185,2.858,2.858,0,0,0,1.614-.483,2.821,2.821,0,0,0,1.053-1.426,6.2,6.2,0,0,0,.345-2.135,5.857,5.857,0,0,0-.376-2.219,2.74,2.74,0,0,0-1.088-1.379,2.893,2.893,0,0,0-1.567-.448,2.982,2.982,0,0,0-1.357.461l-1.007.5v-.5L11.667,5.17H5.4v6.3a2.253,2.253,0,0,0,.345,1.288,1.188,1.188,0,0,0,1.053.5,1.761,1.761,0,0,0,1.041-.367,3.758,3.758,0,0,0,.872-.9.313.313,0,0,1,.113-.15.232.232,0,0,1,.151-.056.523.523,0,0,1,.295.125.627.627,0,0,1,.16.426,2.492,2.492,0,0,1-.056.313A3.035,3.035,0,0,1,8.3,13.99a2.667,2.667,0,0,1-1.508.458,3.9,3.9,0,0,1-2.821-.8,3.006,3.006,0,0,1-.777-2.194V5.17H0V4H3.2V1.332L2.47.6V0H4.6l.8.414V4l8.282-.025L14.5,4.8l-5.078,5.1a3.413,3.413,0,0,1,.959-.232,4.157,4.157,0,0,1,1.856.53,3.812,3.812,0,0,1,1.6,1.427,5.035,5.035,0,0,1,.721,1.721,7.25,7.25,0,0,1,.172,1.474,6.163,6.163,0,0,1-.627,2.73,4,4,0,0,1-1.881,1.881,6.086,6.086,0,0,1-2.709.627Z'
													transform='translate(0 0)'
													fill='#e33b5c'
												/>
											</g>
										</svg>
									</div>
									<div className='accepted-payment-item'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='41.373'
											height='13.36'
											viewBox='0 0 41.373 13.36'
										>
											<g id='visa-seeklogo.com' transform='translate(0)'>
												<path
													id='Path_169'
													data-name='Path 169'
													d='M21.4-78.4c-.024,1.861,1.659,2.9,2.926,3.517,1.3.634,1.739,1.04,1.734,1.606-.01.867-1.039,1.25-2,1.265a6.984,6.984,0,0,1-3.433-.816l-.605,2.831a10.066,10.066,0,0,0,3.717.686c3.511,0,5.808-1.733,5.821-4.42.014-3.41-4.717-3.6-4.685-5.124.011-.462.452-.955,1.419-1.081a6.293,6.293,0,0,1,3.3.578l.588-2.739a8.96,8.96,0,0,0-3.128-.574c-3.3,0-5.629,1.757-5.648,4.271M35.82-82.432a1.524,1.524,0,0,0-1.423.948L29.382-69.509H32.89l.7-1.929h4.287l.405,1.929h3.092l-2.7-12.923H35.82m.491,3.491,1.013,4.853H34.55l1.76-4.853M17.143-82.432,14.378-69.509h3.343l2.764-12.923H17.143m-4.946,0-3.48,8.8L7.31-81.115a1.558,1.558,0,0,0-1.542-1.317H.08L0-82.057a13.918,13.918,0,0,1,3.3,1.1,1.415,1.415,0,0,1,.794,1.136L6.758-69.509h3.533l5.417-12.923H12.2'
													transform='translate(0 82.668)'
													fill='#e33b5c'
												/>
											</g>
										</svg>
									</div>
								</div>

								<div className='accepted-payments-mobile'>
									<Paragraph className='accepted-here-text'>
										Accepted Here
									</Paragraph>
								</div>
							</Col>
						</Row>
					</div>
				</section>

				<section className='cross-chain-interoperability' id='interoperability'>
					<div className='container'>
						<Row justify='center' align='middle' gutter={[40, 8]}>
							<Col lg={12}>
								<div className='image-wrap'>
									<img
										src={`${CDN}/landing/token-sale/wheel.png`}
										alt='cherry token'
									/>
								</div>
							</Col>
							<Col lg={12}>
								<div className='text-wrap'>
									<Title level={2}>Cross Chain Interoperability</Title>
									<Paragraph>
										The Cherry Token and Cherry Chain is compatible with the
										best in class blockchains so that users can take advantage
										of features being developed across the space.
									</Paragraph>
									<Paragraph>
										Gain exposure to every major smart contract platform with
										interoperability across multiple blockchain ecosystems.
									</Paragraph>
								</div>
							</Col>
						</Row>
					</div>
				</section>

				<section className='the-cherry-token' id='utility'>
					<div className='cherry-token-container'>
						<Row justify='center' align='top' gutter={[60, 8]}>
							<Col span={12} lg={12} sm={24} xs={24}>
								<div className='cherry-token-top-container'>
									<Title level={2}>
										The <span style={{ color: '#E33B5C' }}>Cherry </span>Token
									</Title>
									<Text>
										<Text level={3} className='bold'>
											Cherry Token (CHER)
										</Text>{' '}
										is the Ecosystem's native token designed to empower the
										Cherry platform and foster a mutually beneficial
										relationship between developers, users and investors.
									</Text>
									<div className='image-wrap'>
										<img
											src={`${CDN}/landing/token-sale/man_with_phone.svg`}
											alt='cherry token'
										/>
									</div>
								</div>
							</Col>
							<Col span={12} lg={12} sm={24} xs={24}>
								<div className='cherry-token-top-right-container'>
									<div className='utility'>
										<Title level={3} className='primary'>
											Currency
										</Title>
										<Text className='text-20'>
											CHER is used as a "standard" currency within the Cherry
											economy: fans use it to tip and cherish artists, it is the
											default payment method for marketplace transactions,
											developers spend them to operate their dApps.
										</Text>
									</div>
									<div className='utility'>
										{/* <Icon name='token-sale/decentralized-finance' /> */}
										<Title level={3} className='primary'>
											Decentralized Finance
										</Title>
										<Text className='p-20 text-20'>
											Access all of Cherry's Defi properties through Cherry
											Token, Invest or get funded easily and for no fees.
										</Text>
									</div>
									<div className='utility'>
										{/* <Icon name='token-sale/blockchain-fee' /> */}
										<Title level={3} className='primary'>
											Blockchain Fees
										</Title>
										<Text className='p-20 text-20'>
											The Cherry Chain offers powerful features which cost
											expensive computational resources to maintain. Pay the
											fees incurred while interacting with the decentralized
											applications hosted on the Cherry Chain with CHER
										</Text>
									</div>
									<div className='utility'>
										{/* <Icon name='token-sale/funding' /> */}
										<Title level={3} className='primary'>
											Funding
										</Title>
										<Text className='p-20 text-20'>
											The Cherry Project is endowed with a 10 year budget to pay
											developers and proposals, and operate independently from
											outside resources.
										</Text>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</section>
				<section className='cherrynomics' id='cherrynomics'>
					<div className='container'>
						<Title level={2} className='text-center'>
							Cherrynomics
						</Title>
						<br />
						<div className='bubbles'>
							<img
								src={`${CDN}/landing/token-sale/cherrynomics_bg.svg`}
								alt='background'
								className='background'
							/>
							<Bubble
								title='0.03$/CHER'
								subtitle='Current Price'
								id='bubble-1'
							/>
							<Bubble
								title='10 years'
								subtitle='of DAO self-sufficiency'
								id='bubble-2'
							/>
							<Bubble
								title='4 million CHER'
								subtitle='Available monthly to the DAO'
								id='bubble-3'
							/>
							<Bubble
								title='1 billion CHER'
								subtitle='Total Diluted Market Capitalization'
								id='bubble-4'
							/>
							<Bubble
								title='136.5 million CHER'
								subtitle='Circulating Supply'
								id='bubble-5'
							/>
						</div>
					</div>
				</section>
				<section className='cherry-distribution' id='distribution'>
					<div className='container'>
						<Row justify='center'>
							<Col sm={24} lg={24}>
								<Title
									level={2}
									className='text-center'
									style={{ color: '#E33B5C' }}
								>
									Distribution
								</Title>
								<Paragraph className='text-center text-container-large'>
									<span className='bold'>One billion CHER</span> tokens will be
									created upon launch of the Cherry Project. That constitutes
									the token supply limit, which means that no new Cherry tokens
									will be created in the future. The following pie-chart
									illustrates the initial CHER token distribution at the time of
									issuance.
								</Paragraph>
							</Col>
						</Row>
						<Row justify='center' align='middle' gutter={[60, 40]}>
							<Col span={24} lg={12}>
								<div className='graph'>
									<div className='imag-wrap'>
										{/* <CherDistribution width='100%' height='100%' /> */}
										<PieChart height={570} />
									</div>
								</div>
							</Col>
							<Col span={24} lg={12}>
								<div className='text-wrap'>
									<div className='distribution'>
										<span
											className='legend-bullet'
											style={{ backgroundColor: '#6A071A' }}
										></span>
										<Text>
											<span className='bold'>5%</span> Public Token Sale
										</Text>
									</div>
									<div className='distribution'>
										<span
											className='legend-bullet'
											style={{ backgroundColor: '#880922' }}
										></span>
										<Text>
											<span className='bold'>7.5%</span> Liquidity Pools
										</Text>
									</div>
									<div className='distribution'>
										<span
											className='legend-bullet'
											style={{ backgroundColor: '#920C27' }}
										></span>
										<Text>
											<span className='bold'>48%</span> Decentralized Autonomous
											Organization
										</Text>
									</div>
									<div className='distribution'>
										<Text>
											<span
												className='legend-bullet'
												style={{ backgroundColor: '#C22242' }}
											></span>
											<span className='bold'>10%</span> Strategic Partnerships
											and Community Incentives
										</Text>
									</div>
									<div className='distribution'>
										<Text>
											<span
												className='legend-bullet'
												style={{ backgroundColor: '#E33B5C' }}
											></span>
											<span className='bold'>2.75%</span> Seed Investors
										</Text>
									</div>
									<div className='distribution'>
										<span
											className='legend-bullet'
											style={{ backgroundColor: '#E33B5C' }}
										></span>
										<Text>
											<span className='bold'>12.25%</span> Pre-sale Investors
										</Text>
									</div>
									<div className='distribution'>
										<span
											className='legend-bullet'
											style={{ backgroundColor: '#F85D7B' }}
										></span>
										<Text>
											<span className='bold'>4.5%</span> Founders
										</Text>
									</div>
									<div className='distribution'>
										<span
											className='legend-bullet'
											style={{ backgroundColor: '#FE708C' }}
										></span>
										<Text>
											<span className='bold'>7.5%</span> Cherry Labs
										</Text>
									</div>
									<div className='distribution'>
										<span
											className='legend-bullet'
											style={{ backgroundColor: '#FF879E' }}
										></span>
										<Text>
											<span className='bold'>2.5%</span> Advisors
										</Text>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</section>
				<section className='cherry-vesting-schedule' id='vesting'>
					<div className='container'>
						<Row justify='center'>
							<Col>
								<Title level={2} className='text-center'>
									Vesting Schedule
								</Title>
								<Paragraph className='text-container-large'>
									<Link to='/token'>Cherry Tokens</Link> either sold or granted
									to Strategic Partners, Early Investors, the Founders, Advisors
									<span style={{ color: '#E43C5C' }}>*</span> and the Cherry
									Labs Team<span style={{ color: '#E43C5C' }}>*</span> will
									follow a vesting schedule which will continue to be
									distributed in 2025 and beyond. The graph shows the number and
									breakdown of the CHER tokens that will become accessible on a
									monthly basis.
								</Paragraph>
							</Col>
						</Row>
						<Row align='middle' gutter={[60, 20]}>
							<Col lg={{ span: 12, order: 1 }} span={24} order={1}>
								<div className='vesting-schedule-graph'>
									<div className='image-wrap'>
										<AreaChart height={300} />
										{/* <Vesting
											width='100%'
											height='100%'
											className='mobile-hidden'
										/>
										<VestingMobile
											width='100%'
											height='100%'
											className='desktop-hidden'
										/> */}
									</div>
									<div className='legends'>
										<div className='legend-bullets'>
											<ul>
												<li>
													<span
														className='legend-bullet'
														style={{ backgroundColor: '#C22242' }}
													></span>
													Partnerships
												</li>
												<li>
													<span
														className='legend-bullet'
														style={{ backgroundColor: '#E33B5C' }}
													></span>
													Seed Investors
												</li>
												<li>
													<span
														className='legend-bullet'
														style={{ backgroundColor: '#EC4F6E' }}
													></span>
													Private Sale
												</li>
												<li>
													<span
														className='legend-bullet'
														style={{ backgroundColor: '#F85D7B' }}
													></span>
													Founders
												</li>
												<li>
													<span
														className='legend-bullet'
														style={{ backgroundColor: '#FE708C' }}
													></span>
													Cherry Labs
												</li>
												<li>
													<span
														className='legend-bullet'
														style={{ backgroundColor: '#FF879E' }}
													></span>
													Advisors
												</li>
											</ul>
										</div>
										<Text className='muted'>
											*While Cherry Tokens may become available to Advisors and
											Cherry Labs at the indicated cliffs, they will not have to
											be distributed at the moment of issuance. Should they not
											be assigned to team members and advisors, they will be
											withheld by the DAO and issued at its convenience.
										</Text>
									</div>
								</div>
							</Col>
							<Col lg={{ span: 12, order: 2 }} span={24} order={1}>
								<Paragraph>
									<Link>
										<strong>Seed & Private Sale Investors</strong>
									</Link>{' '}
									receive 5% of their allocation the day of the launch.
									Thereafter Cherry Tokens are unlocked over the course of six
									months, beginning the 6th month from launch.
								</Paragraph>
								<Paragraph>
									<Link>
										<strong>Cherry Labs</strong>
									</Link>{' '}
									are funded for 5 years from launch, receiving a small portion
									of their allocation every month.
								</Paragraph>
								<Paragraph>
									<Link>
										<strong>Advisors</strong>
									</Link>{' '}
									will receive Cherry Tokens on a per-need basis, which will be
									locked for 12 months and released monthly for the 24 months
									thereafter.
								</Paragraph>
								<Paragraph>
									<Link>
										<strong>Strategic Partnerships</strong>
									</Link>{' '}
									will be funded for 18 months from launch.
								</Paragraph>
								<Paragraph>
									<Link>
										<strong>Founders</strong>
									</Link>{' '}
									will start to be compensated from the 18th month from launch,
									and receive monthly payouts distributed equally for the
									following 42 months.
								</Paragraph>
							</Col>
						</Row>
					</div>
				</section>
				<section className='cherry-treasury' id='treasury'>
					<div className='container'>
						<Row justify='center' align='middle' gutter={[40, 20]}>
							<Col md={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
								<div className='text-wrap'>
									<Title level={2} style={{ color: '#E33B5C' }}>
										The Treasury
									</Title>
									<Paragraph>
										As a Decentralized Autonomous Organization, Cherry is
										governed by its community who vote and execute decisions on
										the future of the project. One of the main tasks of the DAO
										is to administer a monthly budget of{' '}
										<span className='primary'>4 million CHER</span>.
									</Paragraph>
									<Paragraph>
										The Cherry Treasury was established to guarantee for the
										Cherry Project and community proposals for 10 years from
										launch.
									</Paragraph>
									<Paragraph>
										While <span className='primary'>4 million CHER</span> are
										available to be distributed each month, they must be
										allocated to a specific proposal by the DAO through the
										governance system.
									</Paragraph>
									<Paragraph>
										This means that while a maximum of{' '}
										<span className='primary'>4 million CHER</span> may be
										unlocked monthly, the DAO has final say about how many
										tokens it will reward the proposals it approved. The
										unclaimed remainder will be burned and removed from the
										Cherry Token supply forever.
									</Paragraph>
									{/* <Paragraph>
										Learn more about the Cherry DAO and{' '}
										<HashLink smooth to={'/'} className='primary'>
											Governance Protocol here.
										</HashLink>
									</Paragraph> */}
								</div>
							</Col>
							<Col md={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
								<div className='image-wrap'>
									<img
										src={`${CDN}/landing/token-sale/treasury2.svg`}
										alt='Cherry Treasury'
									/>
								</div>
							</Col>
						</Row>
					</div>
				</section>
			</Content>
		</Layout>
	)
}

export function Bubble({ title, subtitle, width, rotate, style, ...props }) {
	return (
		<div className='bubble' style={{ width: `${width}`, ...style }} {...props}>
			<Icon name='stalk' className='stalk' />
			<div className='inner'>
				<span className='bubble-text w-100 text-center'>
					<Title level={3}>{title}</Title>
					<Typography component='span' className='text-16'>
						{subtitle}
					</Typography>
				</span>
			</div>
		</div>
	)
}
