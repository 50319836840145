import React, { useState, useEffect } from 'react'
import Icon from './Icon'
import {
	Input,
	Modal,
	Typography,
	Button,
	Row,
	Col,
	Card,
	Checkbox,
	Select,
	Spin,
} from 'antd'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { useMutation, gql } from '@apollo/client'

const { Text, Title } = Typography
const { Option } = Select
const { TextArea } = Input

const CREATE_WAITLIST = gql`
	mutation CreateWaitList(
		$companyName: String
		$email: String!
		$firstname: String
		$hearAboutUs: String
		$investAmnt: Int!
		$lastname: String
		$phone: String
	) {
		createWaitList(
			data: {
				companyName: $companyName
				email: $email
				firstname: $firstname
				hearAboutUs: $hearAboutUs
				investAmnt: $investAmnt
				lastname: $lastname
				phone: $phone
			}
		) {
			firstname
			email
		}
	}
`

export default function JoinWaitingListModal({ isVisible, closeModal }) {
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [companyName, setCompanyName] = useState('')
	const [phoneNumber, setPhoneNumber] = useState(undefined)
	const [amountInUsd, setAmountInUsd] = useState(1)
	const [howDidYouHear, setHowDidYouHear] = useState('')
	const [acceptedTerms, setAcceptedterms] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [submitting, setSubmitting] = useState(false)

	const [createWaitlist, { data }] = useMutation(CREATE_WAITLIST, {
		onError: (err) => {
			console.log('eeee')
			console.log(err)
		},
	})

	const handleAcceptTerms = (e) => {
		setAcceptedterms(e.target.checked)
	}

	function handleAmountChange(value) {
		setAmountInUsd(parseInt(value))
	}

	function isEmail(val) {
		let regEmail =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		if (!regEmail.test(val)) {
			return 'Invalid Email'
		}
	}

	const submitForm = async (e) => {
		setErrorMessage('')
		if (isEmail(email)) {
			setErrorMessage('*Email is not correctly formatted')
		} else if (
			acceptedTerms &&
			email !== '' &&
			firstName !== '' &&
			lastName !== '' &&
			amountInUsd !== '' &&
			howDidYouHear !== ''
		) {
			try {
				console.log('got here')
				setSubmitting(true)
				const response = await createWaitlist({
					variables: {
						companyName: companyName,
						email: email,
						firstname: firstName,
						investAmnt: amountInUsd,
						lastname: lastName,
						phone: phoneNumber,
						hearAboutUs: howDidYouHear,
					},
					errorPolicy: 'all',
				})

				console.log('Response ' + JSON.stringify(response))

				setSubmitting(false)
				setEmail('')
				setFirstName('')
				setLastName('')
				setCompanyName('')
				setPhoneNumber('')
				setHowDidYouHear('')
				setAcceptedterms(false)
				closeModal()
			} catch (e) {
				// do something with the error here
				setErrorMessage('Error Submitting your request. Please try again!')
				console.log('GQL ERRRROORR')
				console.log(e)
			}
		} else {
			setErrorMessage(
				'*Make sure all mandatory fields are filled and you have accepted the terms of service'
			)
		}
	}

	const updatePhoneNumber = (phone) => {
		setPhoneNumber(phone + '')
	}

	return (
		<Modal
			className={`join-waiting-list-modal ${errorMessage ? 'error' : ''}`}
			centered
			visible={isVisible}
			footer={null}
			header={null}
			onCancel={closeModal}
		>
			<Card className='join-the-waiting-list' style={{ borderRadius: '24px' }}>
				<Row>
					<Col span={24}>
						<Title level={3} className='text-center'>
							<span className='primary'>Join</span> the waiting list
						</Title>
					</Col>
					<Col span={24}>
						<Input
							className='input required'
							value={firstName}
							placeholder='First Name*'
							onChange={(e) => setFirstName(e.target.value)}
						/>
					</Col>

					<Col span={24}>
						<Input
							className='input required'
							value={lastName}
							placeholder='Last Name*'
							onChange={(e) => setLastName(e.target.value)}
						/>
					</Col>

					<Col span={24}>
						<Input
							className='input required'
							value={email}
							placeholder='Your Email*'
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Col>

					<Input
						className='input'
						value={companyName}
						placeholder='Company Name'
						onChange={(e) => setCompanyName(e.target.value)}
					/>
					<Col span={24}>
						{/* <Input
							className='input'
							value={phoneNumber}
							placeholder='Phone'
							onChange={(e) => setPhoneNumber(e.target.value)}
						/> */}

						<PhoneInput
							className='input'
							value={phoneNumber}
							placeholder='Your phone number in international format'
							onChange={setPhoneNumber}
						/>
					</Col>

					<Col span={24}>
						{/* <Input
							className='input'
							value={amountInUsd}
							placeholder='Amount in USD'
							onChange={(e) => setAmountInUsd(e.target.value)}
						/> */}
						<Select
							className='select_amount required'
							// defaultValue='<999$'
							suffixIcon={<Icon name='caret-down' />}
							onChange={handleAmountChange}
							placeholder='Estimated investment amount*'
						>
							<Option value='1'>&lt;$999</Option>
							<Option value='2'>$1,000 - $5,000</Option>
							<Option value='3'>$5001 - $25,000</Option>
							<Option value='4'>$25,001 - $99,999</Option>
							<Option value='5'>$100,000+</Option>
						</Select>
					</Col>

					<Col span={24}>
						<TextArea
							rows={3}
							className='text-area required'
							value={howDidYouHear}
							placeholder='How did you hear about us?*'
							onChange={(e) => setHowDidYouHear(e.target.value)}
						/>
					</Col>

					<Col span={24}>
						<Checkbox checked={acceptedTerms} onChange={handleAcceptTerms}>
							By submitting this form you agree to our{' '}
							<span className='primary'>Terms of Service</span> and our{' '}
							<span className='primary'>Conditions</span>
						</Checkbox>
					</Col>

					<Col span={24}>
						<Button
							type='button'
							className='primary-button'
							onClick={submitForm}
						>
							{submitting && <Spin style={{ marginRight: '1rem' }} />}
							Join the waiting list
						</Button>
						<Text>{errorMessage}</Text>
					</Col>
				</Row>
			</Card>
		</Modal>
	)
}
