import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import ReactMarkdown from 'react-markdown'
import Icon from '../../components/Icon'
import { BlockLoading } from 'react-loadingg'

import { HashLink } from 'react-router-hash-link'
import ReactRotatingText from 'react-rotating-text'
import FAQ from '../../components/FAQ'
import performer from '../../assets/images/performer.svg'

import { useMutation, gql } from '@apollo/client'

import {
	Col,
	Row,
	Button,
	Typography,
	Layout,
	Input,
	Card,
	Tooltip,
} from 'antd'

const { Content } = Layout
const { Title, Text, Paragraph } = Typography
// const { Link: AnchorLink } = Anchor
const { REACT_APP_CDN_ENDPOINT: CDN } = process.env

const visions = [
	{
		text: 'We are building an ecosystem of disruptive and innovative technologies, empowering Creators to pursue their art.',
	},
	{
		text: 'We promote all Creators with the audacity to create and the vision to share their art with the world',
	},
	{
		text: 'We are driven by an undying thirst to innovate, and share the ability to think and innovate freely to the world.',
	},
]

const ADD_TO_NEWSLETTER = gql`
	mutation CreateNewsletter($email: String!) {
		createNewsLetter(data: { email: $email }) {
			email
		}
	}
`

export default function Homepage() {
	let history = useHistory()

	const [createNewsLetter, { data }] = useMutation(ADD_TO_NEWSLETTER, {
		onError: (err) => {
			console.log('eeee')
			console.log(err)
		},
	})

	const [email, setEmail] = useState('')
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const [titleArray, setTitleArray] = useState([
		'Social Network',
		'Marketplace',
		'CMS',
		'Subscription Gateway',
		'Payment Processor',
		'Finance Protocol',
		'DAO',
		'Decentralized Bank',
		'Compliance System',
		'Community',
		'Movement',
		'Ecommerce Platform',
	])

	useEffect(() => {
		const listArray = [
			'Social Network',
			'Marketplace',
			'CMS',
			'Subscription Gateway',
			'Payment Processor',
			'Finance Protocol',
			'DAO',
			'Decentralized Bank',
			'Compliance System',
			'Community',
			'Movement',
			'Ecommerce Platform',
		]
		setTitleArray(shuffleArray(listArray))
	}, [])

	const roadmapData = [
		{
			title: 'Proof of Concept',
			text: `Formulate vision / Publish manifesto / Assemble team / Identify tech stack / Design architecture / Industry experts feedback / DLT research`,
			image: <Icon name='roadmap-alpha' />,
			button: 'Now Live',
			active: true,
		},
		{
			title: 'Launch and community growth',
			text: 'Canary blockchain network launch / Beta Layer 2 / Decentralized Application suite MVP / Community / Ecosystem growth / Creators outreach / Advisory board formed',
			image: <Icon name='roadmap-beta' />,
			button: 'Coming in Q3 2021',
		},
		{
			title: 'Continuous delivery',
			text: 'Transition to DAO / Hackathons / zkSNARKs integration / IPFS integration / Iterate dApp suite / Release Cherry SDK / Security audit',
			image: <Icon name='roadmap-v1' />,
			button: 'Coming in Q4 2021',
		},
	]

	function shuffleArray(array) {
		let i = array.length - 1
		for (; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1))
			const temp = array[i]
			array[i] = array[j]
			array[j] = temp
		}
		return array
	}

	function isEmail(val) {
		let regEmail =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		if (!regEmail.test(val)) {
			return false
		} else {
			return true
		}
	}

	const meetCommunityEmailCalled = async () => {
		setErrorMessage('')

		if (email === '' && email === '') {
			setErrorMessage('Please enter your email')
		} else if (!isEmail(email)) {
			setErrorMessage('Check Email Format')
		} else {
			setLoading(true)

			try {
				const response = await createNewsLetter({
					variables: {
						email: email,
					},
					errorPolicy: 'all',
				})
				// console.log(JSON.stringify(response) + ' Is the response ')
				setEmail('')
				setLoading(false)
			} catch (e) {
				// do something with the error here
				console.log('GQL ERRRROORR')
				console.log(e)
			}
		}
	}

	const navigateToTokenPage = () => {
		history.push('/token')
	}

	return (
		<Layout className='homepage-layout'>
			<Content className='homepage-content'>
				<section
					className='social-network-for-creators'
					id='social-network-for-creators'
				>
					<div className='container'>
						<Row justify='space-between' align='top' gutter={[40, 20]}>
							<Col lg={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
								<div className='top-left-container'>
									<Title level={1} className='rotating-title'>
										<div>
											The
											<span style={{ color: '#E5395D', marginLeft: '8px' }}>
												<ReactRotatingText items={titleArray} />
											</span>{' '}
											<br /> for Creators.
										</div>
									</Title>
									<Text>
										<strong>Publish</strong>, <strong>market</strong>, and{' '}
										<strong>monetize</strong> art without friction.
									</Text>
									<div className='buttons'>
										<Button
											className='primary-button mt-10'
											type='button'
											onClick={navigateToTokenPage}
										>
											$CHER Token
										</Button>
										<Button
											className='primary-button mt-10'
											type='button'
											// onClick={navigateToJoinTheEcosystem}
										>
											<HashLink
												smooth
												to='#join-the-cherry-ecosystem'
												style={{ textDecoration: 'none' }}
											>
												Join the Cherry Ecosystem
											</HashLink>
										</Button>
										{/* <HashLink
											smooth
											className='hashlink'
											to='#join-the-cherry-ecosystem'
										>
											Join the Cherry Ecosystem
										</HashLink> */}
									</div>
									<HashLink smooth className='arrow-down' to='#vision'>
										<Icon name='arrow-down' />
									</HashLink>
								</div>
							</Col>
							<Col lg={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
								<div className='image-wrap'>
									<img
										// src={`${CDN}/landing/home/performer.svg`}
										src={performer}
										alt='Social Network for Creators'
										width='100%'
									/>
								</div>
							</Col>

							<Card size='small' className='litepaper-announcement'>
								<Row gutter={12} align='middle' justify='center'>
									<Col span={16} sm={24} md={16} lg={16}>
										{/* <div className='litepaper-card'> */}
										<span
											className='primary'
											style={{ textDecoration: 'underline' }}
										>
											<a
												href='https://assets.cherry.network/lightpaper.pdf'
												target='_blank'
												rel='noreferrer'
												style={{ fontSize: '16px' }}
											>
												View our newly released lightpaper
											</a>
										</span>
										{/* </div> */}
									</Col>
									<Col span={8} lg={5}>
										<a
											href='https://assets.cherry.network/lightpaper.pdf'
											className='icon-wrapper'
											target='_blank'
											rel='noopener noreferrer'
											style={{
												backgroundColor: '#E33B5C',
												marginTop: '12px',
												padding: '28px 15px',
												borderRadius: '12px',
											}}
										>
											<Icon name='litepaper' />
										</a>
									</Col>
								</Row>
							</Card>
						</Row>
					</div>
					<div className='container-fluid community-stats'>
						<div className='container'>
							<Row align='middle' justify='space-between'>
								<Col>
									<div className='news-outlets'>
										<Icon name='home/yahoo' />
										<Icon name='home/coindesk' />
										<Icon name='home/forbes' />
										<Icon name='home/binance' />
									</div>
								</Col>
								<Col>
									<div className='community-counters'>
										<div>
											<Icon name='trending-up' />
											<Text>8.2k Wallets</Text>
										</div>
										<div>
											<Icon name='twitter' />
											<Text>+25k</Text>
										</div>
										<div>
											<Icon name='telegram' />
											<Text>+47k</Text>
										</div>
										<div>
											<Icon name='discord' />
											<Text>+17k</Text>
										</div>
										<div>
											<Icon name='github' />
											<Text>12k Contributions</Text>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</section>
				<section className='cherry-vision' id='vision'>
					<div className='main-container container'>
						<Row justify='space-around' align='top' gutter={[40, 20]}>
							<Col span={12} sm={24} md={12} lg={12} xs={24}>
								<div className='image-wrap'>
									<img
										className='cherry-vision-image'
										src={`${CDN}/landing/home/cherry_vision.svg`}
										alt='Cherry Vision'
									/>
								</div>
							</Col>
							<Col span={12} xs={24} sm={24} md={12} lg={12}>
								<Title level={2} className='primary'>
									Cherry Vision
								</Title>

								<Paragraph
									className='vision-text'
									style={{ fontWeight: 'bold' }}
								>
									We believe that art only truly emerges when creativity is
									allowed to roam free, unbound by conventions, censorship, or
									the whims of the powerful. Cherry is committed to making
									artistic expression accessible to all.
								</Paragraph>

								{visions.map((vision, key) => (
									<div className='vision-points' key={key}>
										<div className='img-cap'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='13.295'
												height='13.295'
												viewBox='0 0 13.295 13.295'
											>
												<rect
													id='Rectangle_74'
													data-name='Rectangle 74'
													width='13.295'
													height='13.295'
													rx='3'
													fill='#e43c5c'
												/>
											</svg>
										</div>
										<div className='w-100'>
											<Paragraph>{vision.text}</Paragraph>
										</div>
									</div>
								))}
							</Col>
						</Row>
					</div>
				</section>

				<section className='cherry-chain-card' id='chain'>
					<div className='container'>
						<Row>
							<Col span={24} sm={24} md={24} id='cherry-chain'>
								<Card
									size='large'
									className='cherry-card'
									style={{ width: '100%' }}
								>
									<Row>
										<Col span={24} lg={16}>
											<Title level={2} className='primary'>
												The Cherry Chain
											</Title>
											<Paragraph className='text-18'>
												At the core of the Cherry Project lies the Cherry Chain,
												a layer one solution custom built to suit the needs of
												our community. Cherry Chain features advanced blockchain
												technologies, utilizing Nominated Proof of Stake
												consensus, EVM extensions, on-chain upgradability,
												libp2p networking and state of the art cryptography.
											</Paragraph>
											<br />

											<Tooltip placement='right' title='Coming Soon'>
												<Button className='cherry-chain-whitepaper-button'>
													<svg
														className='icon-paper'
														xmlns='http://www.w3.org/2000/svg'
														width='13.508'
														height='18.011'
														viewBox='0 0 13.508 18.011'
													>
														<path
															id='Icon_awesome-file-alt'
															data-name='Icon awesome-file-alt'
															d='M7.88,4.784V0H.844A.842.842,0,0,0,0,.844V17.166a.842.842,0,0,0,.844.844H12.664a.842.842,0,0,0,.844-.844V5.628H8.724A.847.847,0,0,1,7.88,4.784Zm2.251,8.3a.423.423,0,0,1-.422.422H3.8a.423.423,0,0,1-.422-.422V12.8a.423.423,0,0,1,.422-.422h5.91a.423.423,0,0,1,.422.422Zm0-2.251a.423.423,0,0,1-.422.422H3.8a.423.423,0,0,1-.422-.422v-.281a.423.423,0,0,1,.422-.422h5.91a.423.423,0,0,1,.422.422Zm0-2.533v.281a.423.423,0,0,1-.422.422H3.8a.423.423,0,0,1-.422-.422V8.3A.423.423,0,0,1,3.8,7.88h5.91A.423.423,0,0,1,10.131,8.3Zm3.377-4.014V4.5h-4.5V0H9.22a.844.844,0,0,1,.6.246l3.444,3.447A.842.842,0,0,1,13.508,4.288Z'
															fill='#fff'
														/>
													</svg>
													Cherry Chain Technical Whitepaper
												</Button>
											</Tooltip>
										</Col>
										<Col span={24} lg={8}></Col>
										<img
											src={`${CDN}/landing/home/man_lightbulb.svg`}
											alt='cherry chain'
											className='cherry-chain-image'
										/>
									</Row>

									<Row justify='space-around' align='top' gutter={[40, 50]}>
										<Col span={24} xs={24} sm={24} md={12}>
											<div className='chain-feature'>
												<Title level={3} className='primary'>
													Cherry Virtual Machine
												</Title>
												<Text className='text-18'>
													Cherry Chain features full EVM compatibility to build
													and run advanced decentralized applications with the
													same toolkit as the best known blockchains.
												</Text>
											</div>
										</Col>

										<Col span={24} xs={24} sm={24} md={12}>
											<div className='chain-feature'>
												<Title level={3} className='primary'>
													Inter-Planetary File System
												</Title>
												<Text className='text-18'>
													A peer-to-peer hypermedia protocol designed to make
													the web faster, safer, and more open. It allows to
													store and distribute large amounts of data, while
													keeping content private and secure.
												</Text>
											</div>
										</Col>
										<Col span={24} xs={24} sm={24} md={12}>
											<div className='chain-feature'>
												<Title level={3} className='primary'>
													Scale Proof
												</Title>
												<Text className='text-18'>
													Cherry aims to build software used by millions across
													the world, and our infrastructure must be able to
													sustain that scale. The Cherry Chain processes 100,000
													transactions per second with millisecond latency.
												</Text>
											</div>
										</Col>
										<Col span={24} xs={24} sm={24} md={12}>
											<div className='chain-feature'>
												<Title level={3} className='primary'>
													zk-SNARKs
												</Title>
												<Text className='text-18'>
													Zero-Knowledge Succinct Non-Interactive Argument of
													Knowledge are a cryptographic proof construction that
													allow private and lightweight data transmission
													between parties.
												</Text>
											</div>
										</Col>
									</Row>
									<br />
									<Button className='start-developing-button'>
										<a
											href='https://docs.cherry.network'
											style={{ textDecoration: 'none' }}
											target='_blank'
											rel='noreferrer'
										>
											Start Developing on Cherry Chain Today
										</a>
									</Button>
								</Card>
							</Col>
						</Row>
					</div>
				</section>

				<section className='place-where-creators-thrive' id='ecosystem'>
					<div className='main-container'>
						<Row justify='space-around' align='middle' gutter={[50, 20]}>
							<Col span={24} sm={24} md={24}>
								<Title level={2} className='primary'>
									A Place Where Creators Thrive
								</Title>
							</Col>
							<Col span={24} md={24} sm={24}>
								<Text className='text-left text-container-large'>
									<span className='primary'>The Cherry Ecosystem </span>
									is vibrant and diverse. Developers and creators from all
									around the world collaborate in pursuing the Cherry Vision and
									building the future of our Project. Decentralized Applications
									are built on the Cherry Chain, and together aim to form the
									ultimate platform for creative production
								</Text>
							</Col>
							<Col md={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
								<div className='creators-thrive-feature'>
									<Title level={3}>Social Networking</Title>
									<Text className='bold'>Express Yourself</Text>
									<br />
									<Text>
										A space for innovators to create and explore new social
										possibilities, together. Build communities around authentic
										interests, and share valuable art to cherish. Cherry
										promotes active engagement. Artists and those who cherish
										them form a living, breathing, community, evolving adapting
										and changing, together.{' '}
									</Text>
								</div>
								<div className='creators-thrive-feature'>
									<Title level={3}>eCommerce</Title>
									<Text className='bold'>Monetize your content</Text>
									<br />
									<Text>
										Creators love creating art, but humans don't run on passion
										alone. Creators can now open new and easy income streams
										which require no technical overhead or complex legal
										structures. Fans can fully appreciate the talents of the
										Creators they cherish, and get special access to their
										artistic process.
									</Text>
								</div>
								<div className='creators-thrive-feature'>
									<Title level={3}>Decentralized Finance</Title>
									<Text className='bold'>Finance 2.0</Text>
									<br />
									<Text>
										Investors earn interest on their assets, while backing the
										artists they most cherish. Creators access a global network
										of liquidity providers. Cut transaction fees. Eliminate
										fraud. Ease compliance. A worldwide marketplace of financial
										products at you fingertips.
									</Text>
								</div>
							</Col>

							<Col md={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
								<div className='image-wrap'>
									<img
										src={`${CDN}/landing/home/creators.svg`}
										alt='Place where creators thrive'
									/>
								</div>
							</Col>
						</Row>
					</div>
				</section>

				<JoinTheEcosystem id='join-the-cherry-ecosystem' />

				<section className='nft-governance' id='nft'>
					<div className='main-container'>
						<Row justify='space-around' align='middle' gutter={[60, 20]}>
							<Col span={24} sm={24} md={12} lg={12} xs={24}>
								<div className='image-wrap'>
									<img
										src={`${CDN}/landing/home/nfts.svg`}
										alt='Non Fungible Tokens'
									/>
								</div>
							</Col>
							<Col span={24} sm={24} md={12} lg={12} xs={24}>
								<Title level={2} style={{ color: '#E33B5C' }}>
									Non Fungible Tokens
								</Title>
								<Paragraph>
									<span className='bold'>The Cherry Chain </span>
									adopts industry standards to enhance development performance
									interoperability across chains, and to integrate battle tested
									features in the Cherry Ecosystem. ERC721, or Non-fungible
									tokens (NFTs) allow the unique manifestation of digital or
									physical items on the Cherry Chain.
								</Paragraph>
								<Paragraph>
									Creators can thus permanently record their art on the public
									Chain, sell it, and earn commissions on its use. Cherishers
									who buy NFTs will always be able to prove the authenticity of
									their purchases, and flaunt their art portfolio to the world.
								</Paragraph>
								<Button className='primary-button'>
									<a
										href='https://docs.cherry.network/nft'
										style={{ textDecoration: 'none' }}
										target='_blank'
										rel='noreferrer'
									>
										Learn more about NFTs
									</a>
								</Button>
							</Col>
						</Row>
					</div>
				</section>
				<section className='governance' id='governance'>
					<div className='main-container'>
						<Row align='middle' gutter={[60, 20]}>
							<Col md={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
								<Title level={2} style={{ color: '#E33B5C' }} id='#governance'>
									Governance
								</Title>
								<Paragraph>
									Cherry is owned and operated by its community as a
									decentralized autonomous organization (DAO). The Cherry
									Project is governed via a democratic and transparent voting
									system which allows all stakeholders to get involved and shape
									Cherry's future.
								</Paragraph>
								<Paragraph>
									<span className='bold'>Cherry Governance Key </span> holders
									determine the future of the Ecosystem with their votes and
									proposals.
								</Paragraph>
								<Tooltip placement='right' title='Coming Soon'>
									<Button className='disabled-button'>Cast your Vote</Button>
								</Tooltip>
							</Col>
							<Col md={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
								<div className='image-wrap'>
									<img
										src={`${CDN}/landing/home/governance.svg`}
										alt='Non Fungible Tokens'
									/>
								</div>
							</Col>
						</Row>
					</div>
				</section>
				<section className='meet-the-worldwide-community' id='community'>
					<div className='container main-container'>
						<Row justify='space-around' align='middle' gutter={[40, 20]}>
							<Col span={24} lg={10}>
								<div className='social-icons'>
									<a
										href='https://twitter.com/Cherry_Network'
										className='icon-wrapper'
										target='_blank'
										rel='noopener noreferrer'
									>
										<Icon name='twitter' width='85%' height='85%' />
									</a>
									<a
										href='https://discord.gg/KAwC9aafWa'
										className='icon-wrapper'
										target='_blank'
										rel='noopener noreferrer'
									>
										<Icon name='discord' width='85%' height='85%' />
									</a>
									<a
										href='https://t.me/cherrynetwork'
										className='icon-wrapper'
										target='_blank'
										rel='noopener noreferrer'
									>
										<Icon name='telegram' width='85%' height='85%' />
									</a>
									<a
										href='https://forum.cherry.network/'
										className='icon-wrapper'
										target='_blank'
										rel='noopener noreferrer'
									>
										<Icon name='messenger' width='85%' height='85%' />
									</a>
								</div>
							</Col>
							<Col span={24} lg={14}>
								<Title level={1} style={{ color: '#E33B5C' }}>
									Meet the worldwide community
								</Title>
								<Text>
									Join a fast-growing community of developers and innovators
									connected all over the world, building the new era of the
									internet.
								</Text>
								<form className='input-email'>
									<Input
										type='email'
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										placeholder={
											errorMessage === '' ? 'Enter your email' : errorMessage
										}
									></Input>
									<Button onClick={meetCommunityEmailCalled}>
										{loading ? (
											<div className='loading'>
												<BlockLoading
													size='small'
													color='#fff'
													style={{ marginLeft: '10px' }}
												/>
											</div>
										) : (
											<Icon name='arrow-right-icon' />
										)}
									</Button>
								</form>
								<p>
									{errorMessage === 'Check Email Format' ? errorMessage : ''}
								</p>
							</Col>
						</Row>
					</div>
				</section>
				<section className='roadmap' id='roadmap'>
					<div className='container'>
						<Row justify='space-around' align='middle' gutter={[40, 20]}>
							<Col span={24} sm={24} md={24}>
								<Title level={1} className='roadmap-title text-center'>
									Roadmap
								</Title>
								{roadmapData.map((roadmap, index) => (
									<div
										className={`roadmap-item ${
											roadmap.active ? 'roadmap-active' : ''
										}`}
										index={index}
									>
										<Row justify='space-between' align='middle'>
											<Col span={4} xs={24} md={24} lg={3}>
												{roadmap.image}
											</Col>
											<Col span={14} xs={24} md={24} lg={12}>
												<div className='text-wrap'>
													<Title level={3}>{roadmap.title}</Title>
													<ReactMarkdown children={roadmap.text} />
												</div>
											</Col>
											<Col span={4} xs={24} md={24} lg={6}>
												<Button className='primary-button'>
													{roadmap.button}
												</Button>
											</Col>
										</Row>
									</div>
								))}
							</Col>
						</Row>
					</div>
				</section>
				<section className='cherry-labs' id='labs'>
					<div className='container main-container'>
						<Row justify='space-around' align='middle' gutter={[40, 20]}>
							<Col span={24} sm={24} lg={12} xs={24}>
								<div className='image-wrap'>
									<img src={`${CDN}/landing/home/labs.svg`} alt='Cherry Labs' />
								</div>
							</Col>
							<Col span={24} sm={24} lg={12} xs={24}>
								<Title level={2} style={{ color: '#E33B5C' }}>
									Labs
								</Title>
								<Paragraph>
									We are dedicated to establishing new open source standards and
									developing decentralized applications.
								</Paragraph>
								<Paragraph>
									Contribute to our open access repositories or implement your
									own ideas which will immediately benefit a vast network of
									specialized audience.{' '}
									<span className='primary'>Cherry Labs</span> will offer grants
									and organize bounties for deserving projects.
								</Paragraph>
							</Col>
						</Row>
					</div>
				</section>

				<section
					className='cherry-blog'
					id='cherry-blog'
					style={{
						display: 'none',
					}}
				>
					<div className='main-container'>
						<Row justify='space-around' align='middle' gutter={[40, 20]}>
							<Col span={24} sm={24} md={24}>
								<Title level={2}>Cherry Blog</Title>
								<Button className='primary-button'>View our Blog</Button>
							</Col>
							<Col span={24} sm={24} md={24}>
								<Row>
									<Col md={8}>
										<div className='blog-card'>
											<p>Insert Card Image</p>
											<p>
												Lorem ipsum dolor sit amet consectetur, adipisicing
												elit. Doloribus voluptas quis soluta suscipit placeat
												porro laboriosam deserunt nobis. Tenetur iusto quasi sed
												cumque veritatis voluptas neque ratione enim, tempora
												error?
											</p>
										</div>
									</Col>
									<Col md={8}>
										<div className='blog-card'>
											<p>Insert Card Image</p>
											<p>
												Lorem ipsum dolor sit amet consectetur, adipisicing
												elit. Doloribus voluptas quis soluta suscipit placeat
												porro laboriosam deserunt nobis. Tenetur iusto quasi sed
												cumque veritatis voluptas neque ratione enim, tempora
												error?
											</p>
										</div>
									</Col>
									<Col md={8}>
										<div className='blog-card'>
											<p>Insert Card Image</p>
											<p>
												Lorem ipsum dolor sit amet consectetur, adipisicing
												elit. Doloribus voluptas quis soluta suscipit placeat
												porro laboriosam deserunt nobis. Tenetur iusto quasi sed
												cumque veritatis voluptas neque ratione enim, tempora
												error?
											</p>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</section>

				<section
					className='cherry-blog'
					id='cherry-blog'
					style={{ display: 'none' }}
				>
					<div className='container main-container'>
						<Row justify='space-around' align='middle' gutter={[40, 20]}>
							<Col span={24} sm={24} md={24}>
								<Title level={2}>Cherry Blog</Title>
								<Button className='primary-button'>View our Blog</Button>
							</Col>
							<Col span={24} sm={24} md={24}>
								<Row justify='space-around'>
									<Col md={8}>
										<div className='blog-card'>
											<p>Insert Card Image</p>
											<p>
												Lorem ipsum dolor sit amet consectetur, adipisicing
												elit. Doloribus voluptas quis soluta suscipit placeat
												porro laboriosam deserunt nobis. Tenetur iusto quasi sed
												cumque veritatis voluptas neque ratione enim, tempora
												error?
											</p>
											<div className='image'>
												<img
													src={`${CDN}/landing/home/blog_1.svg`}
													alt='Cherry Labs'
												/>
											</div>
											<div className='text'>
												<Title level={3}>
													Lorem ipsum dolor sit amet, consetetur sadipscing{' '}
												</Title>
											</div>
										</div>
									</Col>
									<Col md={8}>
										<div className='blog-card'>
											<p>Insert Card Image</p>
											<p>
												Lorem ipsum dolor sit amet consectetur, adipisicing
												elit. Doloribus voluptas quis soluta suscipit placeat
												porro laboriosam deserunt nobis. Tenetur iusto quasi sed
												cumque veritatis voluptas neque ratione enim, tempora
												error?
											</p>
											<div className='image'>
												<img
													src={`${CDN}/landing/home/blog_1.svg`}
													alt='Cherry Labs'
												/>
											</div>
											<div className='text'>
												<Title level={3}>
													Lorem ipsum dolor sit amet, consetetur sadipscing{' '}
												</Title>
											</div>
										</div>
									</Col>
									<Col md={8}>
										<div className='blog-card'>
											<p>Insert Card Image</p>
											<p>
												Lorem ipsum dolor sit amet consectetur, adipisicing
												elit. Doloribus voluptas quis soluta suscipit placeat
												porro laboriosam deserunt nobis. Tenetur iusto quasi sed
												cumque veritatis voluptas neque ratione enim, tempora
												error?
											</p>
											<div className='image'>
												<img
													src={`${CDN}/landing/home/blog_1.svg`}
													alt='Cherry Labs'
												/>
											</div>
											<div className='text'>
												<Title level={3}>
													Lorem ipsum dolor sit amet, consetetur sadipscing{' '}
												</Title>
											</div>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</section>

				<section className='token-and-faq' id='token-and-faq'>
					<div className='container'>
						<Row
							justify='center'
							align='middle'
							className='token'
							gutter={[60, 20]}
						>
							<Col lg={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
								<div className='text-wrap'>
									<Title level={2} color='#E33B5C'>
										Token
									</Title>
									<Text>
										<span className='primary text-left'>The Cherry Token</span>{' '}
										is the currency valid across the entire Cherry Ecosystem.
										Use it to purchase items on the Cherry Marketplace, to
										interact with Artists on Cherry Social, and to participate
										in the DeFi features offered in Cherry Finance.
									</Text>
								</div>
							</Col>
							<Col lg={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
								<div className='image-wrap'>
									<img src={`${CDN}/landing/home/chain_link.svg`} alt='' />
								</div>
							</Col>
						</Row>
						<FAQ page='home' />
					</div>
				</section>

				<JoinTheEcosystem id='join-the-cherry-ecosystem2' />
			</Content>
		</Layout>
	)
}

function JoinTheEcosystem(props) {
	const [email, setEmail] = useState('')
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const [createNewsLetter, { data }] = useMutation(ADD_TO_NEWSLETTER, {
		onError: (err) => {
			console.log('eeee')
			console.log(err)
		},
	})

	function isEmail(val) {
		let regEmail =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		if (!regEmail.test(val)) {
			return false
		} else {
			return true
		}
	}

	const joinEcosystemEmail = async () => {
		console.log('Join ecosystem')
		setErrorMessage('')
		if (email === '' && email === '') {
			setErrorMessage('Please enter your email')
		} else if (!isEmail(email)) {
			setErrorMessage('Check Email Format')
		} else {
			setLoading(true)

			try {
				const response = await createNewsLetter({
					variables: {
						email: email,
					},
					errorPolicy: 'all',
				})
				// console.log(JSON.stringify(response) + ' Is the response ')
				setEmail('')
				setLoading(false)
			} catch (e) {
				// do something with the error here
				console.log('GQL ERRRROORR')
				console.log(e)
			}
		}
	}

	return (
		<section className='join-the-cherry-ecosystem' {...props}>
			<div className='main-container'>
				<img
					src={`${CDN}/landing/home/3_people.svg`}
					alt='Cherry Labs'
					className='image-ecosystem'
				/>
				<Row justify='space-between' align='middle' gutter={[40, 20]}>
					<Col span={24} sm={24} lg={17} md={24}>
						<Title level={2}>Join the Cherry Ecosystem</Title>
						<div className='separator'>
							<div className='column'>
								<Paragraph className='text-white bold text-20'>
									Keep in touch!
								</Paragraph>
								<Paragraph className='text-20'>
									Get notified about major developments in Cherry Ecosystem.
								</Paragraph>
								<form className='input-email'>
									<Input
										type='email'
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										placeholder={
											errorMessage === '' ? 'Enter your email' : errorMessage
										}
									></Input>
									<Button onClick={joinEcosystemEmail}>
										{loading ? (
											<div className='loading'>
												<BlockLoading
													size='small'
													color='#fff'
													style={{ marginLeft: '10px' }}
												/>
											</div>
										) : (
											<Icon name='arrow-right-icon' />
										)}
									</Button>
								</form>
								<p style={{ margin: '0' }}>
									{errorMessage === 'Check Email Format' ? errorMessage : ''}
								</p>
							</div>
							<div className='column'>
								<Paragraph className='text-white bold text-20'>
									Build on Cherry
								</Paragraph>
								<Paragraph className='text-20'>
									Create a new app or port your existing solidity project to
									Cherry.
								</Paragraph>
								<a
									className='ant-btn ant-btn-primary'
									href='https://docs.cherry.network'
									target='_blank'
									rel='noreferrer'
								>
									Get Started with Cherry
								</a>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</section>
	)
}
