import React, { useState } from 'react'

const CTX = React.createContext()

export { CTX }

export default function Store(props) {
	const [activeIndex, setActiveIndex] = useState(null)

	return (
		<CTX.Provider value={[activeIndex, setActiveIndex]}>
			{props.children}
		</CTX.Provider>
	)
}
