import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'

export default function Guage(props) {
	const config = {
		minValue: 0,
		maxValue: 10,
		maxAngle: 130,
		minAngle: -130,
		ringInset: 20,
		ringWidth: 60,
		pointerWidth: 50,
		pointerTailLength: 0,
		pointerHeadLengthPercent: 0.85,
		transitionMs: 3500,
		guageSectionColors: ['#262626', '#FFA891', '#E33B5C'],
		cornerRadius: 8,
		tickData: ['0', '0.15', '0.66'],
	}
	const guage = useRef(null)
	const filters = useRef(null)
	const r = 300
	const range = config.maxAngle - config.minAngle

	const arc = d3
		.arc()
		.innerRadius(r - config.ringWidth - config.ringInset)
		.outerRadius(r - config.ringInset)
		.startAngle(function (d, i) {
			var ratio = d
			return deg2rad(config.minAngle + ratio * range)
		})
		.endAngle(function (d, i) {
			var ratio = config.tickData[i + 1] || 1
			return deg2rad(config.minAngle + ratio * range)
		})

	const scale = d3
		.scaleLinear()
		.range([0, 1])
		.domain([config.minValue, config.maxValue])

	scale.ticks(config.majorTicks)

	const pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent)
	const lineData = [
		[config.pointerWidth / 2, 0],
		[0, -pointerHeadLength],
		[-(config.pointerWidth / 2), 0],
		[0, config.pointerTailLength],
		[config.pointerWidth / 2, 0],
	]

	useEffect(() => {
		const svgFilters = d3.select(filters.current).html()

		const svg = d3
			.select(guage.current)
			.html(null)
			.attr('class', 'gauge')
			.attr('width', props.width)
			.attr('height', props.width)
			.attr('viewBox', '0, 0, 600, 600')

		svg.append('defs').html(svgFilters)

		const arcs = svg
			.append('g')
			.attr('class', 'arc')
			.attr('transform', `translate(${r}, 360)`)

		arcs
			.append('rect')
			.attr('width', '100')
			.attr('height', '9')
			.attr('fill', '#F3C2B5')
			.attr('rx', '6')
			.attr('x', '-300')
			.attr('transform', 'rotate(-40)')

		arcs
			.append('rect')
			.attr('width', '100')
			.attr('height', '8')
			.attr('fill', '#F3C2B5')
			.attr('rx', '6')
			.attr('x', '200')
			.attr('transform', 'rotate(40)')

		const text2 = svg
			.append('g')
			.attr('transform', `translate(${r}, 500)`)
			.append('text')
			.attr('text-anchor', 'middle')
			.attr('font-size', '30px')
			.style('font-weight', '700')

		text2
			.append('tspan')
			.attr('y', '0')
			.text((d) => '172,500,000')

		text2
			.append('tspan')
			.attr('y', '39')
			.attr('x', '2')
			.text((d) => '$CHER left')

		arcs
			.selectAll('path')
			.data(config.tickData)
			.enter()
			.append('path')
			.attr('fill', function (d, i) {
				return config.guageSectionColors[i]
			})
			.attr('d', arc)

		d3.select('.arc').node().getBBox()

		const label1 = svg.append('g').attr('transform', `translate(0, 450)`)

		label1
			.append('path')
			.style('stroke', '#F3C2B5')
			.attr(
				'd',
				d3.line()([
					[0, 0],
					[0, 150],
				])
			)

		const label1text = label1
			.append('text')
			.style('font-size', '26px')
			.attr('transform', 'translate(15 145)')

		label1text.append('tspan').text((d) => 'Strategic ')

		label1text.append('tspan').text((d) => 'Seed Phase')

		const label2 = svg.append('g').attr('transform', `translate(600, 40)`)

		label2
			.append('text')
			.attr('transform', 'translate(-15 0)')
			.style('font-size', '26px')
			.text((d) => 'Public Sale')
			.attr('text-anchor', 'end')
			.attr('dominant-baseline', 'hanging')

		label2
			.append('path')
			.style('stroke', '#F3C2B5')
			.attr(
				'd',
				d3.line()([
					[0, 0],
					[0, 150],
				])
			)

		const label3 = svg.append('g').attr('transform', `translate(50, 10)`)

		label3
			.append('text')
			.attr('transform', 'translate(15 0)')
			.attr('dominant-baseline', 'hanging')
			.style('font-size', '26px')
			.text((d) => 'Private Sale')

		label3
			.append('path')
			.style('stroke', '#F3C2B5')
			.attr(
				'd',
				d3.line()([
					[0, 0],
					[0, 120],
				])
			)

		const pointerLine = d3.line().curve(d3.curveLinear)

		const pg = svg
			.append('g')
			.data([lineData])
			.attr('class', 'pointer')
			.attr('transform', `translate(${r}, 360)`)

		const pointer = pg
			.append('path')
			.attr('d', pointerLine /*function(d) { return pointerLine(d) +'Z';}*/)
			.attr('fill', '#fff')
			.attr('stroke', '#FCE5DF')
			.attr('transform', 'rotate(' + config.minAngle + ')')

		pg.append('circle')
			.attr('cx', 0)
			.attr('cy', 0)
			.attr('r', 40)
			.attr('fill', '#F5CCC0')

		pointer
			.transition()
			.duration(config.transitionMs)
			.ease(d3.easeElastic)
			.attr('transform', 'rotate(-50)')
	}, [])

	return (
		<div>
			<svg ref={guage}>
				<defs ref={filters}>
					<filter
						id='a'
						x='294.567'
						y='245.648'
						width='100.206'
						height='100.206'
						filterUnits='userSpaceOnUse'
					>
						<feOffset dx='2' dy='2' input='SourceAlpha'></feOffset>
						<feGaussianBlur stdDeviation='3' result='b'></feGaussianBlur>
						<feFlood floodColor='#ff8484' floodOpacity='0.09'></feFlood>
						<feComposite operator='in' in2='b'></feComposite>
						<feComposite in='SourceGraphic'></feComposite>
					</filter>
				</defs>
			</svg>
		</div>
	)
}

function deg2rad(deg) {
	return (deg * Math.PI) / 180
}
